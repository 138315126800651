export const READY_PROPERTY_FORM = 'AddProperty/READY_PROPERTY_FORM'
export const GET_PROPERTY_PURPOSE = 'AddProperty/GET_PROPERTY_PURPOSE'
export const GET_PROPERTY_PURPOSE_DETAIL = 'AddProperty/GET_PROPERTY_PURPOSE_DETAIL'
export const SET_USER_DATA = 'AddProperty/SET_USER_DATA'
export const GET_PROPERTY_TYPE = 'AddProperty/GET_PROPERTY_TYPE'
export const GET_CITIES = 'AddProperty/GET_CITIES'
export const GET_AREA_UNITS = 'AddProperty/GET_AREA_UNITS'
export const GET_CUSTOMERS = 'AddProperty/GET_CUSTOMERS'
export const GET_EMPLOYEES = 'AddProperty/GET_EMPLOYEES'
export const SEARCH_CUSTOMERS = 'AddProperty/SEARCH_CUSTOMERS'
export const INITIAL_CUSTOMERS = 'AddProperty/INITIAL_CUSTOMERS'
export const READY_PROPERTY_SUCCESS = 'AddProperty/READY_PROPERTY_SUCCESS'
export const SUBMIT_PROPERTY_FORM = 'AddProperty/SUBMIT_PROPERTY_FORM'
export const SUCCESS_PROPERTY_FORM = 'AddProperty/SUCCESS_PROPERTY_FORM'
