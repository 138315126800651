import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import './App.scss';
import '../src/scss/_custom.scss';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import configureStore from './store/index';
import { loggedIn } from './services/authService';
const loading = () => (
  // <div className="animated fadeIn pt-3 text-center">
  <div
    style={{
      height: '100%',
      width: '100%',
      // background: '#FFFFFFBB',
      zIndex: 1000,
      position: 'absolute',
      top: 0,
      left: 0,
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 3,
      }}
    >
      <Spin size="large" />
    </div>
  </div>
);

// Containers
const DefaultLayout1 = React.lazy(() => import('./containers/DefaultLayout1'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const store = configureStore();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) =>
                  loggedIn() === true ? (
                    <DefaultLayout {...props} />
                  ) : (
                    <DefaultLayout1 {...props} />
                  )
                }
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
