import { all } from 'redux-saga/effects';

import LoginSaga from './LoginSaga';
import UpdateLoginSaga from './UpdateLoginSaga';
import RegisterSaga from './RegisterSaga';
import UpdateRegisterSaga from './UpdateRegisterSaga';
import PasswordRegisterSaga from './PasswordRegisterSaga';
import PropertyReadySaga from './PropertyReadySaga';
import SubmitPropertySaga from './SubmitPropertySaga';
import watchPostJobForm from './PostJobSaga';
import RoleTableSaga from './RoleTableSaga';
import roleSaga from './RoleSaga';
import deleteRoleSaga from './DeleteRoleSaga';
import AssignRoleSaga from './AssignRoleSaga';
import AssignPermissionSaga from './AssignPermissionSaga';
import ForgetPassword from './ForgetPassword';

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    RoleTableSaga(),
    ForgetPassword(),
    roleSaga(),
    deleteRoleSaga(),
    AssignRoleSaga(),
    AssignPermissionSaga(),
    SubmitPropertySaga(),
    PropertyReadySaga(),
    UpdateLoginSaga(),
    RegisterSaga(),
    watchPostJobForm(),
    UpdateRegisterSaga(),
    PasswordRegisterSaga(),
  ]);
}
