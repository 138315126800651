export const SUBMIT_EMPLOYEE_FORM = 'AddEmployee/SUBMIT_EMPLOYEE_FORM'
export const GET_EMPLOYEE_TABLE = 'AddEmployee/GET_EMPLOYEE_TABLE'
export const DISPLAY_EMPLOYEE_TABLE = 'AddEmployee/DISPLAY_EMPLOYEE_TABLE'
export const GET_INITIAL_EMPLOYEE_TABLE = 'AddEmployee/GET_INITIAL_EMPLOYEE_TABLE'
export const DELETE_EMPLOYEE = 'AddEmployee/DELETE_EMPLOYEE'
export const CHECK_EMAIL_EMPLOYEE = 'AddEmployee/CHECK_EMAIL_EMPLOYEE'
export const CHECK_PHONE_EMPLOYEE = 'AddEmployee/CHECK_PHONE_EMPLOYEE'
export const EMAIL_EXIST_EMPLOYEE = 'AddEmployee/EMAIL_EXIST_EMPLOYEE'
export const EMAIL_UNIQUE_EMPLOYEE = 'AddEmployee/EMAIL_UNIQUE_EMPLOYEE'
export const PHONE_EXIST_EMPLOYEE = 'AddEmployee/PHONE_EXIST_EMPLOYEE'
export const PHONE_UNIQUE_EMPLOYEE = 'AddEmployee/PHONE_UNIQUE_EMPLOYEE'
