import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { READY_PROPERTY_FORM } from '../constants/Property';
import {
  fetchEmployeeTable,
  getCountriesWithoutPagination,
  fetchPropertyType,
  fetchCitiesWithoutPagination,
  fetchAreaUnits,
  fetchPropertyPurposeDetail,
} from '../services';
import {
  getpropertypurpose,
  getemployees,
  getpropertypurposedetail,
  getpropertytype,
  readypropertysuccess,
  getcities,
  getareaunits,
  initialcustomers,
} from '../actions/Property';

export const getPage = (state) => state.nextPage;
const { delay } = require('redux-saga');

export function* handlePropertyForm() {
  try {
    // const data1 = yield call(getCountriesWithoutPagination);

    // yield put(getpropertypurpose(data1));

    yield put(readypropertysuccess());
  } catch (error) {}
}

export default function* watchPropertyForm() {
  yield takeEvery(READY_PROPERTY_FORM, handlePropertyForm);
}
