import { combineReducers } from "redux";

import registerReducer from "./RegisterReducer";
import loginReducer from "./LoginReducer";
import propertyReducer from "./PropertyReducer";
import employeeReducer from "./EmployeeReducer";
import defaultLayoutReducer from "./DefaultLayoutReducer";
import { postJobReducer } from "./PostJobReducer";
import roleReducer from './RoleReducer';

const rootReducer = combineReducers({
  register: registerReducer,
  role: roleReducer,
  signIn: loginReducer,
  property: propertyReducer,
  employee: employeeReducer,
  postjob: postJobReducer,

  defaultLayout: defaultLayoutReducer
});

export default rootReducer;
