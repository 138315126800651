import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { UPDATE_NEW_USER } from '../constants/Register';
import { RegisterNewUser } from '../services';
import { successupdateemail, spinnerbuttonemail } from '../actions/Register';

export function* handleUpdateUser(data) {
  try {
    yield put(spinnerbuttonemail());
    const res = yield call(RegisterNewUser, data.payload);

    if (res == 200) {
      yield put(successupdateemail());
    } else {
    }
  } catch (error) {}
}

export default function* watchUpdateRegister() {
  yield takeEvery(UPDATE_NEW_USER, handleUpdateUser);
}
