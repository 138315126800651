import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { LOGIN_UPDATE } from '../constants/Login';
import { UpdateLoginForm } from '../services';
import { loggingsuccess } from '../actions/Login';

export function* handleLoginUpdateForm(data) {
  try {
    const status = yield call(UpdateLoginForm, data.payload);
    if (status == 200) {
      yield put(loggingsuccess());
    }
  } catch (error) {}
}

export default function* watchLoginUpdateForm() {
  yield takeEvery(LOGIN_UPDATE, handleLoginUpdateForm);
}
