import { put, call, takeEvery, select, take, delay } from 'redux-saga/effects';
import { message } from 'antd';
import { REGISTER_NEW_USER } from '../constants/Register';
import { RegisterNewUser, RegisterUser } from '../services';
import {
  successregisteruser,
  blockemail,
  erroremail,
  inverterroremail,
  spinnerbuttonemail,
} from '../actions/Register';
const key = 'updatable';
export function* handleRegisterUser(data) {
  try {
    yield put(spinnerbuttonemail());
    const response = yield call(RegisterUser, data.payload);
    const res = response.status;
    if (res == 200) {
      if (response?.recaptchaCount >= 5) {
        data.newFunction();
        sessionStorage.setItem('RegisterationRecaptcha', true);
      }
      data.function();
      yield put(successregisteruser());
      message.destroy(key);
    } else if (res === 404) {
      if (response.Errors && response.Errors.length > 0) {
        message.error({ content: res.Errors[0]?.Message, key });
      }
      yield put(blockemail());
      yield delay(5000);
      yield put(inverterroremail());
    } else {
      if (response.Errors && response.Errors.length > 0) {
        message.error({ content: response.Errors[0]?.Message, key });
      }
      yield put(erroremail());
      yield delay(5000);
      yield put(inverterroremail());
    }
  } catch (error) {}
}

export default function* watchRegister() {
  yield takeEvery(REGISTER_NEW_USER, handleRegisterUser);
}
