import { message } from 'antd';
import { put, call, takeEvery, select, take, delay } from 'redux-saga/effects';
import { ASSIGN_PERMISSION } from '../constants/Roles';
import { assignPermission, fetchAssignedPermissions } from '../services';
import { getassignpermissiontable } from '../actions/Roles';
// import { loggingsuccess, loggingnewuser,loginloadingstart,loginloadingstop} from '../actions/Login'
// import { erroremail,inverterroremail } from '../actions/Register';

export function* handleAssignPermission(data) {
  try {
    // yield put(loginloadingstart())
    const status = yield call(assignPermission, data.payload);
    if (status == 200) {
      message.success(`${process.env.REACT_APP_PERMISSION_ASSIGNED}`, [1]);
      data.callback();
      const data2 = yield call(fetchAssignedPermissions);

      yield put(getassignpermissiontable(data2));
    } else {
      message.error(
        `${process.env.REACT_APP_PERMISSION_SECTION_WRONG_HAPPENED}`,
        [1]
      );
    }
  } catch (error) {}
}

export default function* watchAssignPermission() {
  yield takeEvery(ASSIGN_PERMISSION, handleAssignPermission);
}
