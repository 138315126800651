import CryptoJS from 'crypto-js';
import { notification } from 'antd';
const key = 'updatable';
export function convertCurrency(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Crore';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lacs';
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + ' Thousand';
  }

  return val.toString();
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getRoleId = () => {
  if (localStorage.getItem('hiredroid_key_8')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_8'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return false;
  }
};
export const getPromptFlag = () => {
  return (
    localStorage.getItem('hiredroid_key_47') &&
    localStorage.getItem('hiredroid_key_47')
  );
};
export const getJwtToken = () => {
  return localStorage.getItem('hiredroid_key_49');
};
export const getSkillsCount = () => {
  if (localStorage.getItem('hiredroid_key_22')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_22'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 15;
  }
};
export const getWorkExpCount = () => {
  if (localStorage.getItem('hiredroid_key_23')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_23'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 30;
  }
};
export const getUserEduCount = () => {
  if (localStorage.getItem('hiredroid_key_24')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_24'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 10;
  }
};
export const getUserCertCount = () => {
  if (localStorage.getItem('hiredroid_key_25')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_25'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 10;
  }
};
export const getUserProjCount = () => {
  if (localStorage.getItem('hiredroid_key_26')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_26'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 10;
  }
};
export const getInterviewerCount = () => {
  if (localStorage.getItem('hiredroid_key_27')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_27'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 15;
  }
};
export const getProfileFlag = () => {
  if (localStorage.getItem('hiredroid_key_10')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_10'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return 0;
  }
};
export const getInterviewExist = () => {
  if (localStorage.getItem('hiredroid_key_21')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_21'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 0;
  }
};
export const getUserId = () => {
  if (localStorage.getItem('hiredroid_key_1')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_1'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getCompanyexist = () => {
  if (localStorage.getItem('hiredroid_key_5')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_5'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return Number(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 0;
  }
};
export const base64toBlob = (data) => {
  // if (
  //   data.includes(
  //     'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
  //   )
  // ) {
  //   const base64WithoutPrefix = data.split('base64,')[1];
  //   const bytes = atob(base64WithoutPrefix);
  //   let { length } = bytes;
  //   const out = new Uint8Array(length);
  //   while (length--) {
  //     out[length] = bytes.charCodeAt(length);
  //   }
  //   return new Blob([out], {
  //     type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //   });
  // }
  const base64WithoutPrefix = data.split('base64,')[1];

  const bytes = atob(base64WithoutPrefix);
  let { length } = bytes;
  const out = new Uint8Array(length);
  /* eslint-disable-next-line no-plusplus */
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};
export const getIsOwner = () => {
  if (localStorage.getItem('hiredroid_key_3')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_3'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return 0;
  }
};
export const getMemberCheck = () => {
  if (localStorage.getItem('hiredroid_key_7')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_7'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData) {
      var t = true;
    } else {
      var t = false;
    }
    return t;
  } else {
    return false;
  }
};
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
export function hasDupsSimple(array = []) {
  var valueArr = array.map(function (item) {
    return item.interviewer_email;
  });
  var isDuplicate = valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) != idx;
  });
  return isDuplicate;
}
export const getCompanyId = () => {
  if (localStorage.getItem('hiredroid_key_18')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_18'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getAgencyid = () => {
  return localStorage.getItem('hiredroid_key_41');
};
export const getIndustryFlag = () => {
  if (localStorage.getItem('hiredroid_key_14')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_14'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return 1;
  }
};
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const getUserCity = () => {
  if (localStorage.getItem('hiredroid_key_15')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_15'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getUserCountry = () => {
  if (localStorage.getItem('hiredroid_key_9')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_9'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getCompanyCity = () => {
  if (localStorage.getItem('hiredroid_key_13')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_13'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getCompanyCountry = () => {
  if (localStorage.getItem('hiredroid_key_6')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_6'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const getAgencyName = () => {
  return localStorage.getItem('agencyName');
};

export const getFirstName = () => {
  var bytes = CryptoJS.AES.decrypt(
    localStorage.getItem('hiredroid_key_17'),
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  var byte = CryptoJS.AES.decrypt(
    localStorage.getItem('hiredroid_key_16'),
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  let i = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  let j = JSON.parse(byte.toString(CryptoJS.enc.Utf8));
  return i + ' ' + j;
};
export const openRoleChange = (type) => {
  notification[type]({
    message: 'Role Changed',
    duration: process.env.REACT_APP_NOTIFICATION_DURATION,
    key: key,
    description: process.env.REACT_APP_ROLE_CHANGE,
  });
};
export const openSessionExpired = (type) => {
  notification[type]({
    message: 'Session expired.',
    duration: process.env.REACT_APP_NOTIFICATION_DURATION,
    key: key,
    description: 'Session has expired. Kindly login again',
  });
};
export const getUserFlag = () => {
  return (
    localStorage.getItem('hiredroid_key_19') &&
    Number(localStorage.getItem('hiredroid_key_19'))
  );
};
export const openNotification = (placement) => {
  notification.info({
    message: `Pending/Rejected profile`,
    description:
      'Your profile is either in pending state or rejected therefore you cannot apply to jobs unless these changes are approved by the administrator or you remove them.',
    placement,
    duration: process.env.REACT_APP_NOTIFICATION_DURATION,
    style: {
      font: '1.5em',
    },
  });
};
export const openMember = (placement) => {
  notification.info({
    message: `Unauthorized application`,
    description:
      'You cannot apply to jobs outside of your company from your company domain email account.',
    placement,
    duration: process.env.REACT_APP_NOTIFICATION_DURATION,
    style: {
      font: '1.5em',
    },
  });
};
export const getLastName = () => {
  var byte = CryptoJS.AES.decrypt(
    localStorage.getItem('hiredroid_key_16'),
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  return JSON.parse(byte.toString(CryptoJS.enc.Utf8));
};
export const getSession = () => {
  if (localStorage.getItem('hiredroid_key_48')) {
    var byte = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_48'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(byte.toString(CryptoJS.enc.Utf8));
  } else {
    return false;
  }
};
export const getProfileImg = () => {
  if (
    !localStorage.getItem('hiredroid_key_42') ||
    localStorage.getItem('hiredroid_key_42') ===
      'http://167.99.62.73:3000:3000/image/' ||
    localStorage.getItem('hiredroid_key_42') ===
      'https://hiredroid.com:3000/image/'
  ) {
    return null;
  }
  return (
    localStorage.getItem('hiredroid_key_42') &&
    localStorage.getItem('hiredroid_key_42')
  );
};
export const getCompanyName = () => {
  if (localStorage.getItem('hiredroid_key_4')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_4'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export const handleCheckboxChange = (e, type, setState) => {
  const isChecked = e.target.checked;

  setState((prev) => {
    if (isChecked) {
      return [...prev, type];
    } else {
      return prev.filter((item) => item !== type);
    }
  });
};
export const getCompanyDomain = () => {
  if (localStorage.getItem('hiredroid_key_100')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_100'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export function getParentCompanyFlag() {
  return localStorage.getItem('hiredroid_key_101') &&
    localStorage.getItem('hiredroid_key_101') == 'true'
    ? true
    : false;
}

export const getAgencyLogo = () => {
  return [localStorage.getItem('agencyLogo')];
};
export function removeUserId() {
  return localStorage.removeItem('hiredroid_key_1');
}

export function removeRoleId() {
  return localStorage.removeItem('hiredroid_key_8');
}

export function removeRoles() {
  return localStorage.removeItem('hiredroid_key_12');
}

export function removeAgencyName() {
  return localStorage.removeItem('agencyName');
}

export function removeAgencyid() {
  return localStorage.removeItem('hiredroid_key_41');
}

export function removePermissions() {
  return localStorage.removeItem('hiredroid_key_11');
}
export const getUserEmail = () => {
  if (localStorage.getItem('hiredroid_key_2')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_2'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } else {
    return null;
  }
};
let publish = false;
let timer;
export const customDebounce = (val, call) => {
  // return () => {
  clearTimeout(timer);
  publish = false;
  timer = setTimeout(() => {
    if (publish) {
      call(val, timer);
    }
  }, 1000);
  publish = true;
  // };
};
export const customDebounceWithIndex = (val, call, index) => {
  // return () => {
  clearTimeout(timer);
  publish = false;
  timer = setTimeout(() => {
    if (publish) {
      call(val, timer, index);
    }
  }, 1000);
  publish = true;
  // };
};

export function titleCase(str) {
  var splitStr = str.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const getRoles = () => {
  if (localStorage.getItem('hiredroid_key_12')) {
    var bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('hiredroid_key_12'),
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};
export function normalizePhone(phone) {
  return parseInt(phone.replace(/[^0-9]/g, ''));
  // `int` is now 73443433443.
}
export const createTimeStamp = (dateString, timeString) => {
  const date = new Date(dateString);
  const time = new Date(timeString);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  const newDate = new Date(year, month, day, hours, minutes, seconds);
  const newTimestamp = newDate.toUTCString();
  return newTimestamp;
};
export function calculateCompletion(
  profile = '',
  skills = [],
  projects = [],
  education = [],
  certs = [],
  work = []
) {
  let profileCompletion = 0;
  if (profile) {
    profileCompletion = profileCompletion + 20;
  }
  if (skills.length >= 3) {
    profileCompletion = profileCompletion + 20;
  }
  if (projects.length >= 1) {
    profileCompletion = profileCompletion + 15;
  }
  if (education.length >= 1) {
    profileCompletion = profileCompletion + 15;
  }
  if (certs.length >= 1) {
    profileCompletion = profileCompletion + 15;
  }
  if (work.length >= 1) {
    profileCompletion = profileCompletion + 15;
  }
  return profileCompletion;
}
export const rateArray = [
  'No feedback',
  'Unsatisfactory',
  'Average',
  'Good',
  'Very good',
  'Excellent',
];
export const commentArray = [
  'No feedback',
  'Pending',
  'Unsatisfactory',
  'Average',
  'Good',
  'Very good',
  'Excellent',
];
