import {
  SUCCESS_REGISTER_EMAIL,
  BLOCK_EMAIL,
  LOGGING_OUT,
  SUCCESS_UPDATE_EMAIL,
  SUCCESS_PASSWORD_EMAIL,
  REOPEN_ERROR_EMAIL,
  REOPEN_VERIFICATION_EMAIL,
  ERROR_EMAIL,
  INVERT_ERROR_EMAIL,
  VERIFICATION_EMAIL,
  SPINNER_BUTTON_EMAIL,
  DECRYPT_EMAIL,
  REOPEN_REGISTER_EMAIL,
  REOPEN_LOGIN_EMAIL,
  SUCCESS_REGISTER_USER,
  ERROR_EMAIL_DISABLED,
} from '../constants/Register';

const initialstate = {
  isEmailConfirmSent: false,
  isEmailRegisterConfirmSent: false,
  step2: false,
  userUpdated: false,
  error: false,
  verification: false,
  spinner: false,
  button: false,
  passwordConfirmSent: false,
  message: '',
  emailDisabled: false,
};

const registerReducer = (state = initialstate, action) => {
  switch (action.type) {
    case SUCCESS_REGISTER_EMAIL:
      return {
        ...state,
        isEmailConfirmSent: true,
        spinner: false,
        button: false,
      };
    case SUCCESS_REGISTER_USER:
      return {
        ...state,
        isEmailRegisterConfirmSent: true,
        spinner: false,
        button: false,
      };
    case REOPEN_REGISTER_EMAIL:
      return {
        ...state,
        isEmailConfirmSent: false,
        spinner: false,
        button: false,
      };
    case REOPEN_ERROR_EMAIL:
      return {
        ...state,
        error: false,
        emailDisabled: false,
        spinner: false,
        button: false,
      };
    case REOPEN_VERIFICATION_EMAIL:
      return {
        ...state,
        verification: false,
        spinner: false,
        button: false,
      };
    case REOPEN_LOGIN_EMAIL:
      return {
        ...state,
        isEmailRegisterConfirmSent: false,
        spinner: false,
        button: false,
      };
    case SUCCESS_UPDATE_EMAIL:
      return { ...state, userUpdated: true, spinner: false, button: false };
    case SUCCESS_PASSWORD_EMAIL:
      return {
        ...state,
        passwordConfirmSent: true,
        isEmailConfirmSent: false,
        spinner: false,
        button: false,
      };
    case LOGGING_OUT:
      return { ...state, passwordConfirmSent: false };
    case ERROR_EMAIL:
      return { ...state, error: true, spinner: false, button: false };
    case ERROR_EMAIL_DISABLED:
      return {
        ...state,
        error: false,
        emailDisabled: true,
        spinner: false,
        button: false,
      };
    case VERIFICATION_EMAIL:
      return {
        ...state,
        verification: true,
        isEmailRegisterConfirmSent: false,
        spinner: false,
        button: false,
      };
    case INVERT_ERROR_EMAIL:
      return { ...state, error: false, emailDisabled: false, message: '' };
    case SPINNER_BUTTON_EMAIL:
      return { ...state, spinner: true, button: true };
    case DECRYPT_EMAIL:
      return {
        ...state,
        email: action.payload,
        step2: true,
        userUpdated: true,
      };
    case BLOCK_EMAIL:
      return {
        ...state,
        isEmailRegisterConfirmSent: false,
        spinner: false,
        button: false,
        error: true,
        message:
          'Your account has been locked due to multiple sign up attempts. Please contact administrator for further assistance.',
      };
    default:
      return state;
  }
};

export default registerReducer;
