import {
  INITIAL_CUSTOMERS,
  GET_EMPLOYEES,
  GET_PROPERTY_PURPOSE_DETAIL,
  READY_PROPERTY_FORM,
  GET_CUSTOMERS,
  GET_AREA_UNITS,
  GET_CITIES,
  GET_PROPERTY_PURPOSE,
  GET_PROPERTY_TYPE,
  READY_PROPERTY_SUCCESS,
  SUCCESS_PROPERTY_FORM,
  SUBMIT_PROPERTY_FORM,
  SET_USER_DATA,
} from '../constants/Property';

const initialstate = {
  isLoaded: false,
  customers: [],
};

const propertyReducer = (state = initialstate, action) => {
  switch (action.type) {
    case READY_PROPERTY_FORM:
      return true;
    case GET_PROPERTY_PURPOSE:
      return { ...state, purpose: action.purpose };
    case SET_USER_DATA:
      return { ...state, userData: action.data };
    case GET_PROPERTY_PURPOSE_DETAIL:
      return { ...state, detail: action.detail };
    case GET_PROPERTY_TYPE:
      return { ...state, types: action.types };
    case GET_CITIES:
      return { ...state, cities: action.cities };
    case GET_AREA_UNITS:
      return { ...state, units: action.units };
    case GET_CUSTOMERS:
      return { ...state, customers: action.customers };
    case GET_EMPLOYEES:
      return { ...state, employees: action.employees };
    case INITIAL_CUSTOMERS:
      return { ...state, customers: [] };
    case READY_PROPERTY_SUCCESS:
      return { ...state, isLoaded: true };
    case SUCCESS_PROPERTY_FORM:
      return { ...state, isLoaded: true };
    case SUBMIT_PROPERTY_FORM:
      return { ...state, isLoaded: false };

    default:
      return state;
  }
};

export default propertyReducer;
