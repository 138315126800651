export const REGISTER_NEW_USER = 'Register/REGISTER_NEW_USER';
export const UPDATE_NEW_USER = 'Register/UPDATE_NEW_USER';
export const PASSWORD_NEW_USER = 'Register/PASSWORD_NEW_USER';
export const SUCCESS_REGISTER_EMAIL = 'Register/SUCCESS_REGISTER_EMAIL';
export const BLOCK_EMAIL = 'Register/BLOCK_EMAIL';
export const SUCCESS_UPDATE_EMAIL = 'Register/SUCCESS_UPDATE_EMAIL';
export const SUCCESS_PASSWORD_EMAIL = 'Register/SUCCESS_PASSWORD_EMAIL';
export const ERROR_EMAIL = 'Register/ERROR_EMAIL';
export const ERROR_EMAIL_DISABLED = 'Register/ERROR_EMAIL_DISABLED';
export const VERIFICATION_EMAIL = 'Register/VERIFICATION_EMAIL';
export const REOPEN_ERROR_EMAIL = 'Register/REOPEN_ERROR_EMAIL';
export const REOPEN_VERIFICATION_EMAIL = 'Register/REOPEN_VERIFICATION_EMAIL';
export const INVERT_ERROR_EMAIL = 'Register/INVERT_ERROR_EMAIL';
export const SPINNER_BUTTON_EMAIL = 'Register/SPINNER_BUTTON_EMAIL';
export const DECRYPT_EMAIL = 'Register/DECRYPT_EMAIL';
export const INITIAL_DECRYPT_EMAIL = 'Register/INITIAL_DECRYPT_EMAIL';
export const LOGGING_OUT = 'Register/LOGGING_OUT';
export const REOPEN_REGISTER_EMAIL = 'Register/REOPEN_REGISTER_EMAIL';
export const REOPEN_LOGIN_EMAIL = 'Register/REOPEN_LOGIN_EMAIL';
export const FORGET_PASSWORD = 'Register/FORGET_PASSWORD';
export const SUCCESS_REGISTER_USER = 'Register/SUCCESS_REGISTER_USER';
