import {
  SIGNING_IN,
  LOGIN_LOADING_START,
  LOGIN_LOADING_STOP,
  LOGIN_SUCCESS,
  LOGIN_NEW_USER,
  LOGIN_UPDATE,
  SIGNING_OUT,
} from '../constants/Login';
import { SET_ROLES_AND_PERMISSION } from '../constants/DefaultLayout';
export const loggingin = (logindata, func, func1) => ({
  type: SIGNING_IN,
  payload: logindata,
  function: func,
  function1: func1,
});
export const loggingupdate = (logindata) => ({
  type: LOGIN_UPDATE,
  payload: logindata,
});
export const loggingsuccess = () => ({
  type: LOGIN_SUCCESS,
});
export const loggingout = () => ({
  type: SIGNING_OUT,
});
export const loggingnewuser = () => ({
  type: LOGIN_NEW_USER,
});

export const loginloadingstart = () => ({
  type: LOGIN_LOADING_START,
});

export const loginloadingstop = () => ({
  type: LOGIN_LOADING_STOP,
});
export const setrolesandpermission = (data) => ({
  type: SET_ROLES_AND_PERMISSION,
  payload: data,
});
