import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// if (
//   // !window.location.href.includes('#/') &&
//   window.location.href.includes('shareJob')
// ) {
//   let hrefSplit = window.location.href.split('/');
//   // localStorage.setItem('linkedIn1', hrefSplit[hrefSplit.length - 2]);
//   // localStorage.setItem('linkedIn2', hrefSplit[hrefSplit.length - 1]);
//   sessionStorage.setItem('jobIdPublic', hrefSplit[hrefSplit.length - 2]);
//   sessionStorage.setItem('companyIdPublic', hrefSplit[hrefSplit.length - 1]);
// }
if (window.location.href.includes('register1')) {
  const jobIdPublic = sessionStorage.getItem('jobIdPublic');
  const companyIdPublic = sessionStorage.getItem('companyIdPublic');
  const jobIdUser = localStorage.getItem('hiredroid_key_60');
  const companyIdUser = localStorage.getItem('hiredroid_key_61');
  localStorage.clear();
  if (jobIdUser && companyIdUser) {
    localStorage.setItem('jobIdPublic', jobIdUser);
    localStorage.setItem('companyIdPublic', companyIdUser);
    localStorage.setItem('hiredroid_key_60', jobIdUser);
    localStorage.setItem('hiredroid_key_61', companyIdUser);
  }
  if (jobIdPublic != null && companyIdPublic != null) {
    sessionStorage.setItem('jobIdPublic', jobIdPublic);
    sessionStorage.setItem('companyIdPublic', companyIdPublic);
  }
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
