import { LOGIN_SUCCESS, LOGIN_LOADING_START,LOGIN_LOADING_STOP,LOGIN_NEW_USER,LOGIN_UPDATE,SIGNING_OUT } from '../constants/Login';

const initialstate = {
    isLoggedIn : false,
    isNewUser:false,
    button:false,
    spinner:false,
}

const loginReducer = (state = initialstate, action) => {
    switch (action.type) {
            case LOGIN_SUCCESS:

            return {...state, isLoggedIn: true, isNewUser: false,button:false,spinner:false};
            case LOGIN_UPDATE:

            return {...state,button:true,spinner:true};

            case LOGIN_LOADING_START:

            return {...state,button:true,spinner:true};

            case LOGIN_LOADING_STOP:

            return {...state,button:false,spinner:false};

            case LOGIN_NEW_USER:

            return {...state, isNewUser: true};
            case SIGNING_OUT:

            return {...state, isLoggedIn: false};
        default:
            return state;
    }
};

export default loginReducer;
