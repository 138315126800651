export const SUBMIT_ROLE_FORM = 'AddRole/SUBMIT_ROLE_FORM'
export const GET_ROLE_TABLE = 'AddRole/GET_ROLE_TABLE'
export const DISPLAY_ROLE_TABLE = 'AddRole/DISPLAY_ROLE_TABLE'
export const GET_INITIAL_ROLE_TABLE = 'AddRole/GET_INITIAL_ROLE_TABLE'
export const DISPLAY_PERMISSION_TABLE = 'AddRole/DISPLAY_PERMISSION_TABLE'
export const ASSIGN_PERMISSION = 'AddRole/ASSIGN_PERMISSION'
export const ASSIGN_ROLE = 'AddRole/ASSIGN_ROLE'
export const DELETE_ROLE = 'AddRole/DELETE_ROLE'
export const DISPLAY_ASSIGN_PERMISSION_TABLE = 'AddRole/DISPLAY_ASSIGN_PERMISSION_TABLE'
export const DISPLAY_ASSIGN_ROLE_TABLE = 'AddRole/DISPLAY_ASSIGN_ROLES_TABLE'
