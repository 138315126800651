import {
  GET_EMPLOYEES,
  SEARCH_CUSTOMERS,
  INITIAL_CUSTOMERS,
  SUBMIT_PROPERTY_FORM,
  GET_PROPERTY_PURPOSE,
  GET_CUSTOMERS,
  GET_AREA_UNITS,
  GET_CITIES,
  GET_PROPERTY_TYPE,
  READY_PROPERTY_FORM,
  READY_PROPERTY_SUCCESS,
  GET_PROPERTY_PURPOSE_DETAIL,
  SUCCESS_PROPERTY_FORM,
  SET_USER_DATA
} from '../constants/Property'

export const submitproperty = (resetform, data) => ({
  type: SUBMIT_PROPERTY_FORM,
  payload: data,
  resetform
})

export const readypropertyform = () => ({
  type: READY_PROPERTY_FORM
})
export const submitpropertyfinish = () => ({
  type: SUCCESS_PROPERTY_FORM
})

export const getpropertypurpose = purpose => ({
  type: GET_PROPERTY_PURPOSE,
  purpose
})
export const setuserdata = data => ({
  type: SET_USER_DATA,
  data
})

export const getpropertypurposedetail = detail => ({
  type: GET_PROPERTY_PURPOSE_DETAIL,
  detail
})

export const getpropertytype = types => ({
  type: GET_PROPERTY_TYPE,
  types
})

export const getcities = cities => ({
  type: GET_CITIES,
  cities
})

export const getareaunits = units => ({
  type: GET_AREA_UNITS,
  units
})

export const getcustomers = customers => ({
  type: GET_CUSTOMERS,
  customers
})

export const getemployees = employees => ({
  type: GET_EMPLOYEES,
  employees
})

export const searchcustomers = val => ({
  type: SEARCH_CUSTOMERS,
  payload: val
})

export const initialcustomers = () => ({
  type: INITIAL_CUSTOMERS
})

export const readypropertysuccess = () => ({
  type: READY_PROPERTY_SUCCESS
})
