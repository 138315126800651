import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { postJobAction } from '../actions/PostJob';
import { postJob } from '../services';
import { POST_JOB } from '../constants/PostJob';

export function* handlePostJob(data) {
  try {
    const data1 = yield call(postJob, data.payload);

    yield put(postJobAction(data1));
  } catch (error) {}
}

export default function* watchPostJobForm() {
  yield takeEvery(POST_JOB, handlePostJob);
}
