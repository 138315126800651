import { put, call, takeEvery, select } from 'redux-saga/effects';

// import { setImages, setError } from '../actions';
import { SUBMIT_ROLE_FORM, GET_CONTACT_TABLE } from '../constants/Roles';
import { addRole, fetchRoleTable } from '../services';
import { getroletable } from '../actions/Roles';

export function* handleRoleForm(data) {
  try {
    yield call(addRole, data.payload);
    yield call(handleRoleTable);
    // yield put(setImages(images));
  } catch (error) {
    // yield put(setError(error.toString()));
  }
}
export function* handleRoleTable() {
  try {
    const data = yield call(fetchRoleTable);

    yield put(getroletable(data));
    // yield put(setImages(images));
  } catch (error) {
    // yield put(setError(error.toString()));
  }
}

export default function* watchRoleForm() {
  yield takeEvery(SUBMIT_ROLE_FORM, handleRoleForm);
}
