import * as ActionTypes from '../constants/PostJob';

export const postJobReducer = (
  state = {
    errMess: null,
    job: null,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.POST_JOB_ACTION:
      return {
        ...state,
        errMess: null,
        job: action.payload,
      };

    default:
      return state;
  }
};
