import { put, call, takeEvery, select, take, delay } from 'redux-saga/effects';
import { FORGET_PASSWORD } from '../constants/Register';
import { ForgetPassword } from '../services';
import {
  successregisteremail,
  erroremail,
  inverterroremail,
  spinnerbuttonemail,
} from '../actions/Register';

export function* handleRegisterUser(data) {
  try {
    yield put(spinnerbuttonemail());
    const res = yield call(ForgetPassword, data.payload);

    if (res == 200) {
      yield put(successregisteremail());
    } else {
      yield put(erroremail());
      yield delay(5000);
      yield put(inverterroremail());
    }
  } catch (error) {}
}

export default function* watchRegister() {
  yield takeEvery(FORGET_PASSWORD, handleRegisterUser);
}
