import { put, call, takeEvery, select, take } from 'redux-saga/effects';
// import { setImages, setError } from '../actions';
import { GET_INITIAL_ROLE_TABLE } from '../constants/Roles';
import {
  submitRoleForm,
  fetchRoleTable,
  fetchPermissionTable,
  fetchAssignedPermissions,
  fetchAssignedRoles,
} from '../services';
import {
  getroletable,
  getpermissiontable,
  getassignpermissiontable,
  getassignroletable,
} from '../actions/Roles';

export const getPage = (state) => state.nextPage;
const { delay } = require('redux-saga');

export function* handleRoleTable() {
  try {
    const data = yield call(fetchRoleTable);

    yield put(getroletable(data));
    const data1 = yield call(fetchPermissionTable);

    yield put(getpermissiontable(data1));
    const data2 = yield call(fetchAssignedPermissions);

    yield put(getassignpermissiontable(data2));
    const data3 = yield call(fetchAssignedRoles);

    yield put(getassignroletable(data3));
    // yield put(setImages(images));
  } catch (error) {
    // yield put(setError(error.toString()));
  }
}

export default function* watchDisplayRoleTable() {
  yield takeEvery(GET_INITIAL_ROLE_TABLE, handleRoleTable);
}
