import { SUBMIT_EMPLOYEE_FORM, PHONE_EXIST_EMPLOYEE, PHONE_UNIQUE_EMPLOYEE, DISPLAY_EMPLOYEE_TABLE, DELETE_EMPLOYEE, GET_EMPLOYEE_TABLE,EMAIL_EXIST_EMPLOYEE,EMAIL_UNIQUE_EMPLOYEE} from '../constants/Employees';

const initialstate = {
  isLoaded : false,
  emailError:false,
  phoneError:false
}
const employeeReducer = (state = initialstate, action) => {
    switch (action.type) {
        case SUBMIT_EMPLOYEE_FORM:
            return true;
        case DISPLAY_EMPLOYEE_TABLE:
              return {...state,employees: action.employeetable, isLoaded: true};
              case EMAIL_EXIST_EMPLOYEE:
              return {...state,emailError:true};
              case EMAIL_UNIQUE_EMPLOYEE:
              return {...state,emailError:false};
              case PHONE_EXIST_EMPLOYEE:
              return {...state,phoneError:true};
              case PHONE_UNIQUE_EMPLOYEE:
              return {...state,phoneError:false};
        case DELETE_EMPLOYEE:
              return true;
          default:
              return state;
    }
};

export default employeeReducer;
