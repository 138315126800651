import { message } from 'antd';
import { put, call, takeEvery, select, take, delay } from 'redux-saga/effects';
import { ASSIGN_ROLE } from '../constants/Roles';
import { submitRolesToUser, fetchAssignedRoles } from '../services';
import { getassignroletable } from '../actions/Roles';

export function* handleAssignRole(data) {
  try {
    const status = yield call(submitRolesToUser, data.payload);

    if (status == 200) {
      message.success(`${process.env.REACT_APP_MSG_ROLE_ASSIGNED}`, [1]);
      data.callback();
      const data2 = yield call(fetchAssignedRoles);

      yield put(getassignroletable(data2));
    } else {
      message.error(`${process.env.REACT_APP_MSG_ERROR_MSG}`, [1]);
    }
  } catch (error) {}
}

export default function* watchAssignRole() {
  yield takeEvery(ASSIGN_ROLE, handleAssignRole);
}
