import { POST_JOB, POST_JOB_ACTION } from '../constants/PostJob';

export const postJobAction = (payload) => {
  return {
    type: POST_JOB_ACTION,
    payload: payload,
  };
};
export const postjob = (payload) => {
  return {
    type: POST_JOB,
    payload: payload,
  };
};
