import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { PASSWORD_NEW_USER } from '../constants/Register';
import { RegisterPassword } from '../services';
import { successpasswordemail, spinnerbuttonemail } from '../actions/Register';
import { message } from 'antd';

export function* handlePassword(data) {
  try {
    yield put(spinnerbuttonemail());
    const res = yield call(RegisterPassword, data.payload);

    if (res.is_enabled == 1) {
      yield put(successpasswordemail());
      // window.location.replace("http://localhost:3006/login")
      // window.location.replace("http://159.89.39.208:3006/login")
      data.function(res);
    } else {
      yield put(successpasswordemail());
      message.error(`${process.env.REACT_APP_REQUEST_EXPIRED}`);
    }
  } catch (error) {}
}

export default function* watchPasswordRegister() {
  yield takeEvery(PASSWORD_NEW_USER, handlePassword);
}
