import { SET_ROLES_AND_PERMISSION  } from '../constants/DefaultLayout';

const initialstate = {
}

const defaultLayoutReducer = (state = initialstate, action) => {
    switch (action.type) {
            case SET_ROLES_AND_PERMISSION:
            return {...state,roles: action.payload.roles,permissions: action.payload.permission};
        default:
            return state;
    }
};

export default defaultLayoutReducer;