import { put, call, takeEvery, delay } from 'redux-saga/effects';
import { SIGNING_IN } from '../constants/Login';
import { submitLoginForm } from '../services';
import {
  loggingsuccess,
  loginloadingstart,
  loginloadingstop,
  setrolesandpermission,
} from '../actions/Login';
import { reopenloginemail } from '../actions/Register';
import {
  erroremail,
  inverterroremail,
  resendverification,
  erroremaildisable,
} from '../actions/Register';
import { message } from 'antd';

export function* handleLoginForm(data) {
  try {
    yield put(loginloadingstart());
    const res = yield call(submitLoginForm, data.payload);
    // setTimeout(function() {
    //   localStorage.clear();
    //   let history = useHistory();
    //   history.push('/login')
    // }, 10000);
    if (res.status == 200) {
      // const response = yield call(loginRecheck, data.payload);
      yield put(setrolesandpermission(res));
      yield put(reopenloginemail());

      // if (response.isNewUser) {
      //   yield put(loginloadingstop());
      //   yield put(loggingnewuser());
      // } else {
      yield put(loginloadingstop());
      yield put(loggingsuccess());
      data.function1();
      // }
    } else {
      if (res.statusCode == 422) {
        if (res.Errors[0].ResendLink == '0') {
          localStorage.setItem('verificationEmail', data.payload.user_username);
          yield put(resendverification());
        }
        data.function();
      }
      if (res.Message === 'User account is disable') {
        yield put(loginloadingstop());
        yield put(erroremaildisable());
        yield delay(5000);
        yield put(inverterroremail());
      } else if (res === 404) {
        message.error(
          'Your role is not defined yet. Contact the administration.'
        );
        localStorage.clear();
        yield put(loginloadingstop());
      } else {
        yield put(loginloadingstop());
        yield put(erroremail());
        yield delay(5000);
        yield put(inverterroremail());
      }
    }
  } catch (error) {}
}

export default function* watchLoginForm() {
  yield takeEvery(SIGNING_IN, handleLoginForm);
}
