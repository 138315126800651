import { DISPLAY_ASSIGN_PERMISSION_TABLE ,DISPLAY_ASSIGN_ROLE_TABLE ,SUBMIT_ROLE_FORM, ASSIGN_PERMISSION, DISPLAY_ROLE_TABLE, DISPLAY_PERMISSION_TABLE,DELETE_ROLE,GET_INITIAL_ROLE_TABLE,ASSIGN_ROLE } from "../constants/Roles";

export const addrole = (formdata) => ({
    type: SUBMIT_ROLE_FORM,
    payload: formdata
});

export const getpermissiontable = (permissiontable) => ({
  type: DISPLAY_PERMISSION_TABLE,
  permissiontable
});

export const getassignpermissiontable = (assignpermissiontable) => ({
  type: DISPLAY_ASSIGN_PERMISSION_TABLE,
  assignpermissiontable
});

export const getassignroletable = (assignroletable) => ({
  type: DISPLAY_ASSIGN_ROLE_TABLE,
  assignroletable
});

export const assignpermission = (data,func) => ({
  type: ASSIGN_PERMISSION,
  payload: data,
  callback:func
});

export const assignrole = (data,func) => ({
  type: ASSIGN_ROLE,
  payload: data,
  callback:func
});

export const initialroletable = () => ({
  type: GET_INITIAL_ROLE_TABLE
});
export const getroletable = (roletable) => ({
  type: DISPLAY_ROLE_TABLE,
  roletable
});

export const deleterole = (id) => ({
  type: DELETE_ROLE,
  payload: id
});

