import { setToken, removeToken, isTokenExpired } from './authService';
import CryptoJS from 'crypto-js';
import {
  getRoleId,
  getUserId,
  getCompanyId,
  getAgencyid,
  getUserEmail,
  getJwtToken,
  getUserCountry,
  getUserCity,
  getFirstName,
  getCompanyDomain,
  getIsOwner,
} from '../utilities/index';
import moment from 'moment';
import axios from 'axios';
// const port = 'http://167.99.62.73:3000/';
// const port = 'https://app.softpivot.com/';
// const port = 'http://167.99.62.73:3010/';
// const port = 'http://167.99.62.73:3001/';
const port = 'https://api.hiredroid.com/';
// const port='https://hiredroid.com:3001/'
export const companyCheck = async (name) => {
  var skillObj = {
    company_name: name,
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}uniqueCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};
export const getApplicantApplyInfo = async (id, job) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantApplyInfo/${id}/job/${job}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getHiredroidPDf = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}userProfilePdf/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getPrivateProfiles = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}get-private-users/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getVeterans = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getVeteran`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getEligibility = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getEligibility`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getDisability = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getDisability`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getMedia = async (mediaPage, mediaPageSize, mediaSearch) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getMedia?page_size=25&page=${mediaPage}&search=${mediaSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getApplicationSource = async (query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getApplicationSource?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getAllSuperAdminUsers = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getAllSuperAdminUsers?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getPreference = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getPreference`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getDisciplines = async (page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-discipline`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
//167.99.62.73:3000/getLanguage?page_size=25&page=1&search=
export const getLanguages = async (
  languagesPage,
  languagePerPage,
  searchValue
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getLanguage?page_size=25&page=${languagesPage}&search=${searchValue}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getJobStatus = async (id) => {
  // var skillObj = {
  //   job_post_auto_id: id,
  // };
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobStatuses/${id}/company/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(skillObj),
    }
  );
  const data = await response.json();
  return data;
};
export const uploadExcelFile = async (id, child, csvFile) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}uploadExcelFile/${getUserId()}/Company_Id/${id}/ChildCompanyId/${child}`,
    {
      method: 'post',
      redirect: 'follow',
      body: csvFile,
    }
  );
  const data = await response.json();
  return data;
};
export const uploadPrivateExcelFile = async (csvFile) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}uploadExcelPrivate/userId/${getUserId()}/Company_Id/${getCompanyId()}`,
    {
      method: 'post',
      redirect: 'follow',
      body: csvFile,
    }
  );
  const data = await response.json();
  return data;
};
export const suggestNewSkill = async (skillObj) => {
  let jwtToken = getJwtToken();
  skillObj.skill_user_id = Number(getUserId());
  const response = await fetch(`${port}suggestskill`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};
export const deleteProjectUsers = async (dataObj) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}deleteProjectUsers`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataObj),
  });
  const data = await response.json();
  return data;
};
export const suggestMedia = async (skillObj) => {
  let jwtToken = getJwtToken();
  skillObj.user_id = Number(getUserId());
  const response = await fetch(`${port}suggestMedia`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};

export const suggestInterviewerTypes = async (skillObj) => {
  let jwtToken = getJwtToken();
  skillObj.user_id = Number(getUserId());
  const response = await fetch(`${port}suggestInterviewerTypes`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};
export const suggestLanguage = async (skillObj) => {
  let jwtToken = getJwtToken();
  skillObj.user_id = Number(getUserId());
  const response = await fetch(`${port}suggestLanguage`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};
export const updateUserPrivateFlag = async (id, flag) => {
  let jwtToken = getJwtToken();
  const bodyData = {
    random_user_id: id,
    user_is_active: flag,
  };
  const response = await fetch(`${port}updateUserPrivateFlag`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const suggestNewSkillEdit = async (skillObj) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}suggestskill`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};

export const suggestUniverity = async (uniObj) => {
  let jwtToken = getJwtToken();
  uniObj.university_user_id = Number(getUserId());
  const response = await fetch(`${port}suggestuniversity`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};

export const suggestUniverityEdit = async (uniObj) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}suggestuniversity`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};
export const suggestEducation = async (uniObj) => {
  let jwtToken = getJwtToken();
  uniObj.degree_user_id = Number(getUserId());
  const response = await fetch(`${port}suggestDegree`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};
export const suggestDiscipline = async (uniObj) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}suggestDiscipline`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};
export const suggestEducationEdit = async (uniObj) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}suggestDegree`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};

export const assignUserRole = async (role_id, selectedMemberId) => {
  let jwtToken = getJwtToken();
  var roleDataObj = {
    id: selectedMemberId,
    role_id: role_id,
  };
  const response = await fetch(`${port}assignUserRole`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(roleDataObj),
  });
  const data = await response.json();
  return data;
};
export const getIdFromEmail = async (email) => {
  let jwtToken = getJwtToken();
  var roleDataObj = {
    user_username: email,
  };
  const response = await fetch(`${port}getUserId`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(roleDataObj),
  });
  const data = await response.json();
  return data;
};

export const updateProjectClose = async (jobData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateProjectClose`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jobData),
  });
  const data = await response.json();
  return data;
};

export const disableJob = async (jobData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateJobClose`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jobData),
  });
  const data = await response.json();
  return data;
};

export const roleUpdate = async (id, role_id) => {
  var roleDataObj = {
    id: getUserId(),
    role_id: getRoleId(),
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserRoleId`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(roleDataObj),
  });
  const data = await response.json();
  return data;
};

export const disableUser = async (userStatus, selectedMemberId) => {
  let jwtToken = getJwtToken();
  var roleDataObj = {
    id: selectedMemberId,
    user_is_active: userStatus,
  };
  const response = await fetch(`${port}disableUser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(roleDataObj),
  });
  const data = await response.json();
  return data;
};

export const deleteUserInformation = async (selectedMemberId) => {
  let jwtToken = getJwtToken();
  let id = selectedMemberId;
  const response = await fetch(`${port}deleteUserInformation/userId/${id}`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteCopmanyUserInformation = async (selectedCompany) => {
  let jwtToken = getJwtToken();
  let id = selectedCompany;
  const response = await fetch(
    `${port}deleteCopmanyUserInformation/companyId/${id}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const deleteInterviewTypes = async (selectedType) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}deleteInterviewTypes/${selectedType}`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteApplicationSource = async (applicationSourceId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}deleteApplicationSource/${applicationSourceId}`,
    {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const SuperAdminDelete = async (superAdminId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}SuperAdminDelete/${superAdminId}`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const SuperAdminDisble = async (superAdminId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}SuperAdminDisble/${superAdminId}`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const SuperAdminEnable = async (superAdminId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}SuperAdminEnable/${superAdminId}`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const approveAdminInterviewerTypes = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminInterviewerTypes`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  return data;
};

export const disableCompany = async (selectedCompany, flag) => {
  let jwtToken = getJwtToken();
  var values = {
    user_company_id: selectedCompany,
    flag: flag,
  };
  const response = await fetch(`${port}disableCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};

export const leaveTeam = async (userFlag, selectedMemberId) => {
  let jwtToken = getJwtToken();
  var roleDataObj = {
    user_id: selectedMemberId,
    user_emp_prof_flag: userFlag,
  };
  const response = await fetch(`${port}leaveTeam`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(roleDataObj),
  });
  const data = await response.json();
  return data;
};

export const reopenJob = async (jobId, companyID) => {
  let jwtToken = getJwtToken();
  let obj = {
    job_post_end_date: moment().add(1, 'M').format('YYYY-MM-DD'),
  };

  const response = await fetch(
    `${port}reopenJob/${jobId}/company/${companyID}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    }
  );
  const data = await response.json();

  return response.status;
};
// export const reOpenCloseProject = async (projId, companyID) => {
//   let jwtToken = getJwtToken();
//   let obj = {
//     project_auto_id: projId,
//     company_id: companyID,
//   };

//   const response = await fetch(`${port}reOpenCloseProject`, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(obj),
//   });
//   const data = await response.json();

//   return response.status;
// };
export const reOpenCloseProject = async (projId, companyID) => {
  let jwtToken = getJwtToken();
  let obj = {
    project_auto_id: projId,
    company_id: companyID,
  };
  const response = await fetch(`${port}reOpenCloseProject`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  });
  const data = await response.json();
  if (response.status >= 400) {
    return [];
  } else return data;
};
export const extendJobBy = async (jobId, companyID, end_date) => {
  let jwtToken = getJwtToken();
  let obj = {
    job_post_end_date: end_date,
  };

  const response = await fetch(
    `${port}updateJobExpiry/${jobId}/company/${companyID}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    }
  );
  const data = await response.json();

  return data;
};
export const suggestIndustry = async (uniObj) => {
  let jwtToken = getJwtToken();
  uniObj.industry_user_id = Number(getUserId());
  const response = await fetch(`${port}suggestIndustry`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniObj),
  });
  const data = await response.json();
  return data;
};

export const getIndustries = async (industriesPage, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getIndustries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const getJobAuditTrail = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getJobAuditTrail/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const getJobSearch = async (params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobSearch/${getCompanyId()}?${params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const getManageJobSearch = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobSearch/${getCompanyId()}?job_post_flag=1&flag1`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const pendingProfiles = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-pending-profiles?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const pendingJobs = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-pending-jobs?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};
export const getJobSearchProf = async (params) => {
  const response = await fetch(`${port}get-jobs?${params}`);

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};

export const getViewJobSearchProf = async () => {
  const response = await fetch(`${port}get-jobs?flag=1`);

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();
  return data;
};

export const getJobApplicantsByInterviewer = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    // `${port}getJobApplicantsByInterviewer/1/company/3`
    `${port}getJobApplicantsByInterviewer/${getUserId()}/company/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getAppliedJobs = async (
  pageAppliedJobs,
  pageSizeAppliedJobs,
  query_params
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAppliedJobs/${getUserId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getFavoriteJobs = async (
  query_params,
  pageAppliedJobs,
  pageSizeAppliedJobs
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getFavouriteJobs/${getUserId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getRejectedItems = async (query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getRejectedItems?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getFavoriteUsers = async (query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getFavoriteUsers/${getUserId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const addJobComment = async (commentObj) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}addJobComments`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentObj),
  });
  const data = await response.json();
  return data;
};

export const addManageJobComments = async (commentObj) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}addManageJobComments`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentObj),
  });
  const data = await response.json();
  return data;
};

// export const addManageJobComments = async (commentObj) => {
//   let jwtToken = getJwtToken();

//   const response = await fetch(`${port}/addManageJobComments`, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(commentObj),
//   });
//   const data = await response.json();
//   return data;
// };

export const addInterviewTypes = async (interviewType) => {
  let jwtToken = getJwtToken();
  var values = {
    interview_type_name: interviewType,
  };

  const response = await fetch(`${port}addInterviewTypes`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};

export const addApplicationSource = async (applicationSource) => {
  let jwtToken = getJwtToken();
  var values = {
    application_source_name: applicationSource,
  };

  const response = await fetch(`${port}addApplicationSource`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};

export const createSuperAdmin = async (username, imm_flag) => {
  let jwtToken = getJwtToken();
  var values = {
    user_username: username,
    immutable_flag: imm_flag === 'Yes' ? 1 : 0,
  };

  const response = await fetch(`${port}createSuperAdmin`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};

export const addUserComment = async (commentObj) => {
  let jwtToken = getJwtToken();
  commentObj['company_id'] = getCompanyId();
  const response = await fetch(`${port}addUserComments`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentObj),
  });
  const data = await response.json();
  return data;
};

export const addPrivateUserComments = async (commentObj) => {
  let jwtToken = getJwtToken();
  commentObj['company_id'] = getCompanyId();
  const response = await fetch(`${port}addPrivateUserComments`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentObj),
  });
  const data = await response.json();
  return data;
};

export const checkUserJobApplied = async (userId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}checkUserJobApplied/${userId}/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getJobComments = async (userId, jobId, companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobComments/${userId}/job/${jobId}/company/${companyId}/comment/${getUserId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getManageJobComments = async (jobId, companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getManageJobComments/job/${jobId}/company/${companyId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getUserComments = async (userId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getUserComments/${userId}/user/${getUserId()}/company/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getPrivateUserComments = async (userId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getPrivateUserComments/${userId}/user/${getUserId()}/company/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getInterviewerList = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getInterviewerList/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getApplicantList = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobApplicantsByCompanyInterviewers/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getTotalApplicantList = async (jobId, query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}applicantListOfAppliedjob/${getCompanyId()}/job/${jobId}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getActiveJobs = async (id = '', query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getActiveJobs/${id}?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
  }
  return data;
};
export const getApplicantsByCompany = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantsByCompany/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getApplicantsByCompanyElasticSearch = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}ourApplicantElastic/${getCompanyId()}?des=${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getApplicationsByCompanyElasticSearch = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}AllAppliciations/${getCompanyId()}?des=${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const getAllApplicantionsByCompany = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllApplicantionsByCompany/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getAllApplicantionsOfSingleApplicantByCompany = async (
  userId,
  query_params,
  companyId
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllApplicantionsOfSingleApplicantByCompany/${getCompanyId()}/userId/${userId}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getInvitationMessageTemplate = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInvitationMessageTemplate?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getInvitedUsersByEmail = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInvitedUsersByEmail/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getScheduledInterview = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getScheduledInterview/CompanyId/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getScheduledInterviewByAutoId = async (id) => {
  const response = await fetch(
    `${port}getScheduledInterviewByAutoId/InterviewreAutoId/${id}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getInvitedUsers = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInvitedUsersByUsers/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getInterviewerListAgency = async (companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getInterviewerList/${companyId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const suggestSkill = async (formdata) => {
  const response = await fetch(`${port}suggestskill`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }

  return null;
};
export const inviteForJobByEmail = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}inviteForJobByEmail`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  return data;
};

export const markFavoriteJob = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}createFavoriteUnfavoriteJobs`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }

  return null;
};
export const mergeSuggestion = async (details, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}mergeSuggestion?${query_params}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
  return response.status;
};
export const updateSuggestion = async (details, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateSuggestion?${query_params}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
  return response.status;
};
export const createProjects = async (details) => {
  details.user_id = getUserId();
  details.company_id = getCompanyId();
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}createProjects`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
  return response.status;
};
export const updateProjects = async (details) => {
  details.user_id = getUserId();
  details.company_id = getCompanyId();
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateProjects`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
  return response.status;
};
export const getProjects = async (visibility, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getProjects?project_visibility=${visibility}&${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const addProjectUsers = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}addProjectUsers`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  return response.status;
};
export const getAllCompanyProjects = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllCompanyProjects?company_id=${getCompanyId()}&${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getProjectUsers = async (query_params, id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getProjectUsers?projectId=${id}&${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
// export const getFavoriteUsers = async (query_params, id) => {
//   let jwtToken = getJwtToken();
//   const response = await fetch(
//     `${port}getProjectUsers?projectId=${id}${query_params}`,
//     {
//       method: 'get',
//       headers: {
//         Authorization: `Bearer ${jwtToken}`,
//         'Content-Type': 'application/json',
//       },
//     }
//   );
//   const data = await response.json();
//   return data;
// };
export const getInterviewTypes = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getInterviewTypes?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const retrieveInterviewTypes = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-interviewer-type-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const applicantInterviewSchedule = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}applicantInterviewSchedule`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  return response.status;
};
export const rescheduleCandidateInterview = async (data, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}rescheduleCandidateInterview?${query_params}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  return response.status;
};
export const addApplicantInterview = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}addApplicantInterviewer`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const addApplicantInterviewResentInvite = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}interviewResendEmail`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const teamResentInvite = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}resendInviteUser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const addApplicantInterviewers = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}addApplicantInterviewers`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};

export const verifyEmailForInterviews = async (email) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}checkInterviewers`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  });
  const data = await response.json();
  if (data.status != 200) {
    localStorage.setItem(
      'hiredroid_key_21',
      CryptoJS.AES.encrypt(
        JSON.stringify(0),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    // throw new Error(data.errors);
  } else {
    localStorage.setItem(
      'hiredroid_key_21',
      CryptoJS.AES.encrypt(
        JSON.stringify(1),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
  }
  return data;
};
export const verifyEmail = async (email) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}verifyInterviewer`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const getApplicantForInterviewer = async (email, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobApplicantsByInterviewers?${query_params}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    return [];
  } else return data;
};

export const getScheduledInterviewsOfUsers = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getScheduledInterviewsOfUsers?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    return [];
  } else return data;
};

export const addInterviewerFeedback = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateInterviewerFeedback`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  if (response.status >= 400) {
    return [];
  } else return data;
};
export const interviewConduct = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}interviewConduct/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const updateJobPostSkillCheck = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateJobPostSkillCheck/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getInterviewDate = async (company_id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getInterviewData/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

let companyId;
// Jobs
export const fetchJobs = async () => {
  const response = await fetch(`${port}get-jobs`);
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const fetchSingleJobs = async (jobId, companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getJob/${jobId}/company/${companyId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const fetchCompanyJobs = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getJobPostInfo/${getCompanyId()}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};

export const updateJob = async (formdata) => {
  let jwtToken = getJwtToken();
  // //console.log('formData', formdata);
  const response = await fetch(`${port}updateUserJob`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  // const data = await response.json();
  // //console.log('response', response);
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status == 200) {
    // localStorage.setItem('hiredroid_key_5', 1);
  }
  return null;
};
export const EditJob = async (formdata, updated, otherData) => {
  const bodyData = {
    updated_by: Number(getUserId()),
    updated_by_name: getFirstName(),
    job_post_auto_id: Number(localStorage.getItem('editJobID')),
    job_post_description: formdata.job_post_description,
    job_post_title: formdata.job_post_title,
    // job_recruiter_email: formdata.job_recruiter_email,
    // job_recruiter_id: formdata.job_recruiter_id,
    // job_interviewer_email: formdata.job_interviewer_email,
    // job_interviewer_id: formdata.job_interviewer_id,
    // job_interviewer_name: formdata.job_interviewer_name,
    // job_recruiter_name: formdata.job_recruiter_name,
    RecruitmentManagers: formdata.RecruitmentManagers,
    HiringManagers: formdata.HiringManagers,
    job_updated: updated,
    InterviewerJobQuestions: formdata.InterviewerJobQuestions,
    JobSkills: otherData.UserSkills,
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateJobPostHistory`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  //console.log('response', response);
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status == 200) {
    // localStorage.setItem('hiredroid_key_5', 1);
  }
  return data;
};

export const updateJobDraft = async (formdata) => {
  // //console.log('formData', formdata);
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateUserJobDraft`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  // const data = await response.json();
  // //console.log('response', response);
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status == 200) {
    // localStorage.setItem('hiredroid_key_5', 1);
  }
  return null;
};

export const applyJob = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}jobApply`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};

export const fetchScreeningQuestions = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getGenericQuestions`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const submitContactForm = async (formdata) => {
  const response = await fetch(`${port}ManageContact/CreateContact`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const fetchContactTable = async () => {
  const response = await fetch(`${port}ManageContact/GetUsers`);
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getApplicantInterviewStages = async (userId, jobId) => {
  const jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantInterviewHistory/${userId}/job/${jobId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getRescheduleCandidateInterviews = async (userId, jobId) => {
  const jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getRescheduleCandidateInterviews/UserId/${userId}/JobId/${jobId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const fetchDeleteContact = async (id) => {
  const response = await fetch(`${port}ManageContact/DeleteUsers?id=${id}`, {
    method: 'delete',
  });
  if (response.status >= 400) {
    console.error(response.status);
  }
};

export const addRole = async (values) => {
  var val = {
    Name: values['nf-rolename'],
    Active: values['nf-status'],
    Description: values['nf-description'],
    RoleId: values['nf-roleId'],
  };

  const response = await fetch(`${port}ManageRolesAndPermission/CreateRole`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const deleteRole = async (id) => {
  const response = await fetch(
    `${port}ManageRolesAndPermission/DeleteRole?Id=${id}`,
    {
      method: 'delete',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const fetchRoleTable = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserRoles`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getDevInfo = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserProfileInfo/${getUserId()}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const viewDevInfo = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserProfileInfo/${id}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const viewDevInfoSpecific = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserProfileInfoSpecific/${id}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const viewDevLockedInfo = async (id, jobId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobUserProfileInfo/${id}/job/${jobId}`,
    {
      // method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSubCompnayInfo = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getSubCompany/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const resendInviteChildCompany = async (formdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}resendInviteChildCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formdata),
  });
  const data = await response.json();
  return data;
};
export const getCompnayInfo = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getCompanyProfileInfo/${getCompanyId()}`,
    {
      // method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getCompnayInfoPublic = async (companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getCompanyProfileInfo/${companyId}}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const submitCompanyUpdateAdmin = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateCompanyProfile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};
export const getCompanyJobsForInvite = async (id) => {
  var values = {
    company_id: getCompanyId(),
    user_id: id,
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getCompanyJobs`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};

export const inviteForJob = async (
  job,
  user,
  names,
  msg,
  sender,
  company,
  role,
  subj,
  closing
) => {
  var values = {
    company_id: getCompanyId(),
    company_user_id: getUserId(),
    job_id: job,
    user_id: `${user}`,
    invite_flag: 1,
    message: msg,
    sender_name: sender,
    company_name: company,
    role_name: role,
    subject: subj,
    closing_message: closing,
  };

  if (names) {
    values.user_full_name = names;
  }
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}inviteForJob`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};

export const candidateAcceptInterviewSchedule = async (
  userId,
  interviewFlag,
  autoId
) => {
  var values = {
    interview_schedule_auto_id: autoId,
    user_id: userId,
    candidate_interview_confirmation_flag: Number(interviewFlag),
  };
  const response = await fetch(`${port}candidateAcceptInterviewSchedule`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};

export const getAllChildCompany = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getChildCompany/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getChildCompany = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getSubCompany/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const createChildCompany = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}createChildCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  const data = await response.json();
  return data;
};

export const getMyInterviewFilters = async () => {
  let bodyData = { user_username: getUserEmail() };
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobApplicantsInterviewersGettingFilters`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    }
  );
  const data = await response.json();
  return data;
};
export const getTeamFilters = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getCompanyUsersFilters/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getApplicantsFilters = async (applicantPage, applicantSearch) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantsByCompanyFilters/${getCompanyId()}?page_size=25&page=${applicantPage}&search=${applicantSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getAllApplicantionsApplicantFilters = async (
  applicantPage,
  applicantSearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllApplicantionsApplicantFilters/${getCompanyId()}?page_size=25&page=${applicantPage}&search=${applicantSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getAllInterviewApplicantFilter = async (
  applicantPage,
  applicantSearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllInterviewApplicantFilter/${getCompanyId()}?page_size=25&page=${applicantPage}&search=${applicantSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getAllInterviewCompanyFilter = async (
  companyPage,
  companySearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllInterviewCompanyFilter/${getCompanyId()}?page_size=25&page=${companyPage}&search=${companySearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getAllInterviewInterviewerFilter = async (
  interviewerPage,
  interviewerSearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllInterviewInterviewerFilter/${getCompanyId()}?page_size=25&page=${interviewerPage}&search=${interviewerSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getAllInterviewJobFilter = async (jobPage, jobSearch) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllInterviewJobFilter/${getCompanyId()}?page_size=25&page=${jobPage}&search=${jobSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getAllApplicantionsCompanyFilters = async (
  companyPage,
  companySearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllApplicantionsCompanyFilters/${getCompanyId()}?page_size=25&page=${companyPage}&search=${companySearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getAllApplicantionsJobFilters = async (jobPage, jobSearch) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllApplicantionsJobFilters/${getCompanyId()}?page_size=25&page=${jobPage}&search=${jobSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};
export const updateChildCompany = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateChildCompany`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  const data = await response.json();
  return data;
};
export const submitCompany = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateCompanyProfile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  if (response.status == 200) {
    localStorage.setItem(
      'hiredroid_key_5',
      CryptoJS.AES.encrypt(
        JSON.stringify(1),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(1),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();
    localStorage.setItem('hiredroid_key_3', ciphertext);
  }

  const data = await response.json();
  if (response.status == 200) {
    localStorage.setItem(
      'hiredroid_key_18',
      CryptoJS.AES.encrypt(
        JSON.stringify(data.id ? data.id : data.company_auto_id),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
  }
  return data;
};
export const getJobInfo = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getJobPostInfo/${id}`, {
    // method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const updateJobNote = async (values) => {
  let jwtToken = getJwtToken();

  var noteBody = {
    user_id: values.user_id,
    job_id: values.job_id,
    job_notes: values.job_notes,
  };
  const response = await fetch(`${port}updateJobNote`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(noteBody),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};

export const submitJob = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}createJobPost`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};

export const getAllUsers = async (params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}get-users?${
      params.includes('#') ? params.replace(/#/g, '%23') : params
    }`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getAllUsersElasticSearch = async (params, page, pageSize) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}elasticTalentMarket?des=${params}&page=${page}&page_size=${pageSize}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getPrivateUsersElasticSearch = async (params, page, pageSize) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}ourTalentMarket?des=${params}&page=${page}&page_size=${pageSize}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const scheduleInterviewsElasticSearch = async (
  params,
  page,
  pageSize
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}scheduleInterview?des=${params}&page=${page}&page_size=${pageSize}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getAllActiveUsers = async (page, pageSize, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllActiveUsers?page_size=${pageSize}&page=${page}&search=${search}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getJobApplicants = async (query_params = '', id, status) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicants/${id}/company/${getCompanyId()}/status/${status}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const acceptInviteAPI = async (params_token) => {
  let urlToken = params_token;
  const response = await fetch(`${port}acceptInvite`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${urlToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const enableCompany = async (params_token) => {
  let urlToken = params_token;
  const response = await fetch(`${port}enableCompany`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${urlToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getAllApplicants = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getApplicants/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getAllCompanies = async (companyPage, companySearch) => {
  let jwtToken = getJwtToken();

  const response = await fetch(
    `${port}getCompanies?page_size=25&page=${companyPage}&search=${companySearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getScheduledInterviewApplicantsFilters = async (
  getUserEmail,
  applicantPage,
  applicantSearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getScheduledInterviewApplicantsFilters/${getUserEmail}?page_size=500&page=${applicantPage}&search=${applicantSearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getScheduledInterviewJobFilters = async (
  getUserEmail,
  jobPage,
  jobSearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getScheduledInterviewJobFilters/${getUserEmail}?page_size=500&page=1&search=`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getScheduledInterviewCompanyFilters = async (
  getUserEmail,
  companyPage,
  companySearch
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getScheduledInterviewCompanyFilters/${getUserEmail}?page_size=500&page=${companyPage}&search=${companySearch}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getTeamMember = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getCompanyUsers/${getCompanyId()}?${query_params}${
      getIsOwner() !== 1 && getRoleId() === 1
        ? ''
        : getIsOwner() !== 1
        ? 'user_is_active=1'
        : ''
    }`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getManagers = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getTeamList/${id || getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSuggestedSkill = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-skills-admin?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getPendingSocialMediaAdmin = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-media-admin?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getPendingLanguageAdmin = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-language-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getTotalCountries = async (query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getAllCountries?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getPrivateProfileInfo = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getUserPrivateInfo/${id}/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const updateSalaryPattern = async (data) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateCountryValue`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return false;
};
export const getSuggestedEducation = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-degrees-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSuggestedIndustries = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}get-industries-admin?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSystemConfigs = async (query_params = '') => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getJPKeys?${query_params}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSuggestedDisciplines = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-discipline-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSuggestedUniversity = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-universities-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSuggestedCertification = async (query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}retrieve-certifications-admin?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getCompanyInformation = async (id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getCompanyById/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getApplicantAverageOfJobSkills = async (
  applicandId,
  jobId,
  companyId
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantAverageOfJobSkills/${applicandId}/job/${jobId}/company/${companyId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getApplicantJobSkills = async (applicandId, jobId, status) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantJobSkills/${applicandId}/job/${jobId}/jobStatus/${status}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const EmailSettings = async (userdata) => {
  const response = await fetch(`${port}getEmailSetting`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${userdata}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const updateJPKeys = async (data) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateJPKeys`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return false;
};
export const addJPKey = async (Obj) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}addJPKeys`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Obj),
  });
  const data = await response.json();
  return data;
};
export const updateEmailSettings = async (emailData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateEmailSetting`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailData),
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const NewsLetterTemplates = async (userdata) => {
  const response = await fetch(`${port}getNewsLetterTemplate`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${userdata}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }
  return response;
};
export const EmailTemplates = async (userdata) => {
  const response = await fetch(`${port}getEmailTemplate`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${userdata}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const getInterviewerQuestionsAnswers = async (
  interviewerId,
  jobId,
  applicandId
) => {
  const token = getJwtToken();
  const response = await fetch(
    `${port}getInterviewerQuestionsAnswers/JobId/${jobId}/InterviewerId/${interviewerId}/ApplicantId/${applicandId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const updateNewsLetterTemplates = async (emailTemplateData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateNewsLetterTemplate`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailTemplateData),
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const updateEmailTemplates = async (emailTemplateData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateEmailTemplate`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailTemplateData),
  });

  if (response.status == 200) {
    const data = await response.json();
    return data;
  }

  return response;
};
export const adminDashboard = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}adminDashboard`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSkillsList = async (page, search) => {
  const response = await fetch(
    `${port}retrieve-skills?page_size=25&page=${page}&search=${search}`
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getSkillsListWithoutPagination = async (page, search) => {
  const response = await fetch(
    `${port}retrieve-skills?page_size=&page=&search=`
  );
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const favouriteProfile = async (Obj) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}createFavoriteUsers`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Obj),
  });
  const data = await response.json();
  return data;
};
export const getJobStatusList = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}get-jobpost-status`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getEthnicity = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-ethnicity`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getGender = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-gender`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getMinimumQualificationList = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-degrees`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getQuestionSuggestions = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getGenericQuestions/${getCompanyId()}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getUniversitiesList = async (page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-universities`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const suggestedCertifications = async (Obj) => {
  let jwtToken = getJwtToken();
  Obj.certificate_user_id = Number(getUserId());
  const response = await fetch(`${port}suggestCertification`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Obj),
  });
  const data = await response.json();
  return data;
};

export const suggestedCertificationsEdit = async (Obj) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}suggestCertification`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Obj),
  });
  const data = await response.json();
  return data;
};

export const getCertifications = async (page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}retrieve-certifications`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const fetchPermissionTable = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getPermissions`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getFiltersData = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobApplicantsByCompanyInterviewersGettingFilters/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const RegisterUser = async (userdata) => {
  const response = await fetch(`${port}signup`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  const data = await response.json();

  if (response.status >= 400) {
    console.error(response.status);
  }
  data.status = response.status;
  return data;
};
export const RegisterNewUser = async (userdata) => {
  const response = await fetch(`${port}signup`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  // const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return response.status;
};
export const approveJob = async (degree, jobID, jobflag) => {
  let jwtToken = getJwtToken();
  var skillDataObj = {
    degree_id: degree,
    job_post_auto_id: jobID,
    job_post_flag: jobflag,
  };
  const response = await fetch(`${port}approveAdminJob`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveProfile = async (userId, profileFlag) => {
  let jwtToken = getJwtToken();
  var skillDataObj = {
    id: userId,
    user_profile_flag: profileFlag,
  };
  const response = await fetch(`${port}approveAdminProfile`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveSkill = async (skillId, skillUserId, skillflag) => {
  let jwtToken = getJwtToken();
  var skillDataObj = [
    {
      skill_id: skillId,
      skill_user_id: skillUserId,
      skill_flag: skillflag,
    },
  ];
  const response = await fetch(`${port}approveAdminSkills`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleSkill = async (selectedSkillList) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}approveAdminSkills`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(selectedSkillList),
  });
  const data = await response.json();
  return data;
};
export const approveAdminLanguage = async (skillId, skillUserId, skillflag) => {
  let jwtToken = getJwtToken();
  var skillDataObj = [
    {
      auto_id: skillId,
      user_id: skillUserId,
      flag: skillflag,
    },
  ];
  const response = await fetch(`${port}approveAdminLanguage`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleAdminLanguage = async (languageList) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminLanguage`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(languageList),
  });
  const data = await response.json();
  return data;
};
export const approveAdminMedia = async (skillId, skillUserId, skillflag) => {
  let jwtToken = getJwtToken();
  var skillDataObj = [
    {
      auto_id: skillId,
      user_id: skillUserId,
      flag: skillflag,
    },
  ];
  const response = await fetch(`${port}approveAdminMedia`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleAdminMedia = async (socialMediaList) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminMedia`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(socialMediaList),
  });
  const data = await response.json();
  return data;
};
export const deleteAddNewSkill = async (skillId, skillUserId, skillName) => {
  let jwtToken = getJwtToken();
  var skillDataObj = {
    skill_id: skillId,
    skill_user_id: skillUserId,
    skill_flag: -1,
    skill_name: skillName,
  };
  const response = await fetch(`${port}deleteAddNewSkill`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveUniversity = async (
  universityId,
  universityUserId,
  universtityflag
) => {
  let jwtToken = getJwtToken();
  var univDataObj = [
    {
      university_id: universityId,
      university_user_id: universityUserId,
      university_flag: universtityflag,
    },
  ];
  const response = await fetch(`${port}approveAdminUniversity`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(univDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveMultipleUniversity = async (universitiesList) => {
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}approveAdminUniversity`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(universitiesList),
  });
  const data = await response.json();
  return data;
};
export const deleteAddNewUniversity = async (
  universityId,
  universityUserId,
  universityName
) => {
  let jwtToken = getJwtToken();
  var univDataObj = {
    university_id: universityId,
    university_user_id: universityUserId,
    university_name: universityName,
  };
  const response = await fetch(`${port}deleteAddNewUniversity`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(univDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveDegree = async (degreeId, degreeUserId, degreeflag) => {
  let jwtToken = getJwtToken();
  var degreeDataObj = {
    degree_auto_id: degreeId,
    degree_user_id: degreeUserId,
    degree_flag: degreeflag,
  };
  const response = await fetch(`${port}approveAdminDegree`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(degreeDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveDiscipline = async (degreeId, degreeUserId, degreeflag) => {
  let jwtToken = getJwtToken();
  var degreeDataObj = [
    {
      discipline_id: degreeId,
      discipline_user_id: degreeUserId,
      discipline_flag: degreeflag,
    },
  ];
  const response = await fetch(`${port}approveAdminDiscipline`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(degreeDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleDiscipline = async (desciplineList) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminDiscipline`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(desciplineList),
  });
  const data = await response.json();
  return data;
};

export const deleteAddNewDegree = async (
  degreeId,
  degreeUserId,
  degreeName
) => {
  let jwtToken = getJwtToken();
  var degreeDataObj = {
    degree_auto_id: degreeId,
    degree_user_id: degreeUserId,
    // degree_name: degreeName,
    degree_flag: -1,
  };
  const response = await fetch(`${port}deleteAddNewDegree`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(degreeDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveIndustry = async (industryId, industryUserId, flag) => {
  let jwtToken = getJwtToken();
  var indusDataObj = [
    {
      industry_auto_id: industryId,
      industry_user_id: industryUserId,
      industry_flag: flag,
    },
  ];
  const response = await fetch(`${port}approveAdminIndustry`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(indusDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleIndustry = async (industiresList) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminIndustry`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(industiresList),
  });
  const data = await response.json();
  return data;
};
export const deleteAddNewIndustry = async (
  industryId,
  industryUserId,
  industryName
) => {
  let jwtToken = getJwtToken();
  var indusDataObj = {
    industry_auto_id: industryId,
    industry_user_id: industryUserId,
    industry_name: industryName,
  };
  const response = await fetch(`${port}deleteAddNewIndustry`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(indusDataObj),
  });
  const data = await response.json();
  return data;
};
export const approveCertification = async (
  certificationId,
  certificationUserId,
  flag
) => {
  let jwtToken = getJwtToken();
  var certDataObj = [
    {
      cert_auto_id: certificationId,
      certificate_user_id: certificationUserId,
      certificate_flag: flag,
    },
  ];
  const response = await fetch(`${port}approveAdminCertification`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(certDataObj),
  });
  const data = await response.json();
  return data;
};

export const approveMultipleCertification = async (centificationList) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}approveAdminCertification`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(centificationList),
  });
  const data = await response.json();
  return data;
};
export const deleteAddNewCertificate = async (
  certificationId,
  certificationUserId,
  certificationName
) => {
  let jwtToken = getJwtToken();
  var certDataObj = {
    cert_auto_id: certificationId,
    certificate_user_id: certificationUserId,
    certificate_name: certificationName,
  };
  const response = await fetch(`${port}deleteAddNewCertificate`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(certDataObj),
  });
  const data = await response.json();
  return data;
};
export const ApplicantStatus = async (userdata) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateInterviewStatus`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  // const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return response.status;
};

export const InviteNewUser = async (userdata) => {
  // getUserCountry() != 'null' &&
  //   (userdata['user_country'] = getUserCountry());
  // localStorage.getItem('user_state') != 'null' &&
  //   (userdata['user_state'] = localStorage.getItem('user_state'));
  // getUserCity() != 'null' &&
  //   (userdata['user_city'] = getUserCity());
  // userdata['user_company_id'] = getCompanyId();
  const response = await fetch(`${port}signup`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  // const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return response.status;
};
export const ForgetPassword = async (userdata) => {
  const response = await fetch(`${port}forgotpassword`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  // const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return response.status;
};

export const checkLink = async (userdata) => {
  var val = {
    // EmailAddress: userdata['myuser'],
    user_password: userdata['password'],
  };
  const response = await fetch(`${port}enableuser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${userdata['myuser']}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();

  if (response.status == 200) {
    // // throw new Error(data.errors);
    // console.error(response.status);
    const data = await response.json();
    return data;
  }

  return response;
};
export const RegisterPassword = async (userdata) => {
  getUserCountry() != 'null' && (userdata['user_country'] = getUserCountry());
  localStorage.getItem('hiredroid_key_45') != 'null' &&
    (userdata['user_state'] = localStorage.getItem('hiredroid_key_45'));
  getUserCity() != 'null' && (userdata['user_city'] = getUserCity());
  var val = {
    // "EmailAddress": userdata['EmailAddress'],
    user_password: userdata['password'],
    user_country: userdata['user_country'],
    user_city: userdata['user_city'],
    user_state: userdata['user_state'],
  };
  const response = await fetch(`${port}enableuser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${userdata['myuser']}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();

  if (response.status == 200) {
    // // throw new Error(data.errors);
    // console.error(response.status);
    const data = await response.json();
    return data;
  }

  return response;
};
export const registerCheck = async (data) => {
  const response = await fetch(`${port}passwordCheck`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${data}`,
      'Content-Type': 'application/json',
    },
  });
  const email = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return email;
};

export const decryptingEmail = async (data) => {
  var val = {
    Uri: data,
  };
  const response = await fetch(`${port}ManageAccount/DecryptURL`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const email = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return email;
};

export const isSuperAdmin = async (id) => {
  const jwtToken = getJwtToken();
  const response = await fetch(`${port}isSuperAdmin/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};

export const submitLoginForm = async (data) => {
  const response = await fetch(`${port}login`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  switch (response.status) {
    case 200:
      setToken(res.token);
      if (res.userData?.id) {
        const superAdminResponse = await isSuperAdmin(res.userData?.id);
        localStorage.setItem(
          'hiredroid_key_SA',
          superAdminResponse.isSuperAdmin
        );
        localStorage.setItem('hiredroid_key_TC', superAdminResponse.totalCount);
      }
      localStorage.setItem(
        'hiredroid_key_48',
        CryptoJS.AES.encrypt(
          JSON.stringify(moment()),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      if (res.userData.role_id == 0) {
        localStorage.setItem('hiredroid_key_47', false);
        localStorage.setItem(
          'hiredroid_key_44',
          res.userData.user_country_iso2
        );
        if (res.userCompany[0]) {
          localStorage.setItem(
            'hiredroid_key_14',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userCompany[0]?.industry_flag),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        } else {
          localStorage.setItem(
            'hiredroid_key_14',
            CryptoJS.AES.encrypt(
              JSON.stringify(1),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        }
        if (
          res.userData?.user_profile_flag == 0 ||
          res.userData?.user_profile_flag == 1
        ) {
          localStorage.setItem(
            'hiredroid_key_10',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userData.user_profile_flag),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        } else {
          localStorage.setItem(
            'hiredroid_key_10',
            CryptoJS.AES.encrypt(
              JSON.stringify(1),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        }
        localStorage.setItem(
          'hiredroid_key_5',
          CryptoJS.AES.encrypt(
            JSON.stringify(0),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_18',
          CryptoJS.AES.encrypt(
            JSON.stringify(null),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_9',
          CryptoJS.AES.encrypt(
            JSON.stringify('null'),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem('hiredroid_key_45', null);
        localStorage.setItem(
          'hiredroid_key_15',
          CryptoJS.AES.encrypt(
            JSON.stringify('null'),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        res['status'] = response.status;
        var cipherPermission = CryptoJS.AES.encrypt(
          JSON.stringify([
            'View_Job_Post',
            'View_Employer_Section',
            'View_Applicant_Section',
            'View_Profile_Search',
            'View_Professional_Section',
            'View_Job_Search',
            'View_Company_Search',
            'View_Posted_Job',
            'View_Job_Applicant',
          ]),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('hiredroid_key_11', cipherPermission);
        localStorage.setItem(
          'hiredroid_key_12',
          CryptoJS.AES.encrypt(
            JSON.stringify(['Agency']),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_1',
          CryptoJS.AES.encrypt(
            JSON.stringify(res['userData'].id),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        var ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(-1),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('hiredroid_key_3', ciphertext);
        localStorage.setItem(
          'hiredroid_key_8',
          CryptoJS.AES.encrypt(
            JSON.stringify(0),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem('hiredroid_key_41', null);
        localStorage.setItem(
          'hiredroid_key_17',
          CryptoJS.AES.encrypt(
            JSON.stringify(res.userData.user_first_name),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        var cipheremail = CryptoJS.AES.encrypt(
          res.userData.user_username,
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('hiredroid_key_2', cipheremail);
        localStorage.setItem(
          'hiredroid_key_16',
          CryptoJS.AES.encrypt(
            JSON.stringify(res.userData.user_last_name),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem('hiredroid_key_42', res.userData.user_profile_img);
        // if (!res.userCompany[0]?.company_auto_id) {
        //   localStorage.setItem(
        //     'hiredroid_key_19',
        //     res.userData.user_emp_prof_flag
        //   );
        // } else {
        localStorage.setItem('hiredroid_key_19', 0);
        // }
        if (res.userData.user_emp_prof_flag) {
          localStorage.setItem(
            'hiredroid_key_63',
            res.userData.user_emp_prof_flag
          );
        } else {
          localStorage.setItem('hiredroid_key_63', 0);
        }

        localStorage.setItem(
          'hiredroid_key_4',
          CryptoJS.AES.encrypt(
            JSON.stringify('null'),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_100',
          CryptoJS.AES.encrypt(
            JSON.stringify('null'),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_43',
          res.userData.user_country_iso2
        );
        verifyEmailForInterviews({
          user_username: getUserEmail(),
        });
      } else {
        if (res.userData.user_company_id != 0) {
          var ciphermember = CryptoJS.AES.encrypt(
            'true',
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString();
          localStorage.setItem('hiredroid_key_7', ciphermember);
        } else {
          var ciphermember1 = CryptoJS.AES.encrypt(
            'false',
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString();
          localStorage.setItem('hiredroid_key_7', ciphermember1);
        }
        localStorage.setItem('hiredroid_key_47', false);
        if (res.userCompany[0]) {
          localStorage.setItem(
            'hiredroid_key_14',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userCompany[0]?.industry_flag),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
          localStorage.setItem(
            'hiredroid_key_101',
            res.userCompany[0].company_is_parent == 1 ? true : false
          );
        } else {
          localStorage.setItem(
            'hiredroid_key_14',
            CryptoJS.AES.encrypt(
              JSON.stringify(1),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
          localStorage.setItem('hiredroid_key_101', false);
        }
        localStorage.setItem(
          'hiredroid_key_10',
          CryptoJS.AES.encrypt(
            JSON.stringify(res.userData.user_profile_flag),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        if (res.userPermissions) {
          let permission = res?.userPermissions.map((item) => {
            return item.permission_name;
          });
          var cipherPermission = CryptoJS.AES.encrypt(
            JSON.stringify(permission),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString();
          localStorage.setItem('hiredroid_key_11', cipherPermission);
        } else {
          return 404;
        }
        let roles = res?.userRole.map((item) => {
          return item.role_name;
        });
        if (!res.userCompany[0]?.company_auto_id) {
          localStorage.setItem(
            'hiredroid_key_5',
            CryptoJS.AES.encrypt(
              JSON.stringify(0),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        } else {
          localStorage.setItem(
            'hiredroid_key_18',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userCompany[0]?.company_auto_id),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
          localStorage.setItem(
            'hiredroid_key_5',
            CryptoJS.AES.encrypt(
              JSON.stringify(1),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        }

        localStorage.setItem(
          'hiredroid_key_44',
          res.userData.user_country_iso2
        );
        res.userData.user_country &&
          localStorage.setItem(
            'hiredroid_key_9',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userData.user_country),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        res.userData.user_state &&
          localStorage.setItem('hiredroid_key_45', res.userData.user_state);
        res.userData.user_city &&
          localStorage.setItem(
            'hiredroid_key_15',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userData.user_city),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
        res['status'] = response.status;
        localStorage.setItem(
          'hiredroid_key_12',
          CryptoJS.AES.encrypt(
            JSON.stringify(roles),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem(
          'hiredroid_key_1',
          CryptoJS.AES.encrypt(
            JSON.stringify(res['userData'].id),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        var ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(res['userData'].user_is_owner),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('hiredroid_key_3', ciphertext);
        const roleIdArr = res['userData'].role_id;
        const arrRoleId = [roleIdArr];
        localStorage.setItem(
          'hiredroid_key_8',
          CryptoJS.AES.encrypt(
            JSON.stringify(res['userData'].role_id),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem('hiredroid_key_41', 1);
        localStorage.setItem(
          'hiredroid_key_17',
          CryptoJS.AES.encrypt(
            JSON.stringify(res.userData.user_first_name),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        var cipheremail = CryptoJS.AES.encrypt(
          res.userData.user_username,
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('hiredroid_key_2', cipheremail);
        localStorage.setItem(
          'hiredroid_key_16',
          CryptoJS.AES.encrypt(
            JSON.stringify(res.userData.user_last_name),
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
        );
        localStorage.setItem('hiredroid_key_42', res.userData.user_profile_img);
        // if (res.userData.role_id == 3 || res.userData.user_company_id == 0 ){
        //   localStorage.setItem('hiredroid_key_19', 0);
        // } else{
        // if (!res.userCompany[0]?.company_auto_id) {
        //   localStorage.setItem(
        //     'hiredroid_key_19',
        //     res.userData.user_emp_prof_flag
        //   );
        // } else {
        localStorage.setItem('hiredroid_key_19', 0);
        // }

        // }
        if (res.userData.user_emp_prof_flag) {
          localStorage.setItem(
            'hiredroid_key_63',
            res.userData.user_emp_prof_flag
          );
        } else {
          localStorage.setItem('hiredroid_key_63', 0);
        }
        if (res.userCompany[0]?.company_name) {
          res.userCompany[0].country &&
            localStorage.setItem(
              'hiredroid_key_6',
              CryptoJS.AES.encrypt(
                JSON.stringify(res.userCompany[0].country),
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString()
            );
          res.userCompany[0].city &&
            localStorage.setItem(
              'hiredroid_key_13',
              CryptoJS.AES.encrypt(
                JSON.stringify(res.userCompany[0].city),
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString()
            );
          localStorage.setItem(
            'hiredroid_key_4',
            CryptoJS.AES.encrypt(
              JSON.stringify(
                res.userCompany[0].display_name ||
                  res.userCompany[0].company_name
              ),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
          localStorage.setItem(
            'hiredroid_key_100',
            CryptoJS.AES.encrypt(
              JSON.stringify(res.userCompany[0].company_name),
              process.env.REACT_APP_ENCRYPTION_KEY
            ).toString()
          );
          localStorage.setItem(
            'hiredroid_key_43',
            res.userCompany[0].user_country_iso2
              ? res.userCompany[0].user_country_iso2
              : res.userData.user_country_iso2
          );
        } else {
          res.userData.user_country &&
            localStorage.setItem(
              'hiredroid_key_6',
              CryptoJS.AES.encrypt(
                JSON.stringify(res?.userData?.user_country),
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString()
            );
          res.userData.user_city &&
            localStorage.setItem(
              'hiredroid_key_13',
              CryptoJS.AES.encrypt(
                JSON.stringify(res.userData.user_city),
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString()
            );
          localStorage.setItem(
            'hiredroid_key_43',
            res.userData.user_country_iso2
          );
        }
        verifyEmailForInterviews({
          user_username: getUserEmail(),
        });
        getChildCompany().then((res) => {
          if (!res.Message) {
            let domainArray = [{ company_name: getCompanyDomain() }, ...res];
            const domainValueArray = [];
            domainArray.map((s) => domainValueArray.push(s.company_name));
            domainValueArray.join();
            localStorage.setItem('hiredroid_key_99', domainValueArray);
          }
        });
      }
      // localStorage.setItem("agencyLogo", JSON.stringify(res['agencyLogo']['image'][0]));
      break;
    case 401:
      console.error('401 UnAutthorized');
      removeToken();
      break;
    default:
      removeToken();
      console.error(response);
  }
  isTokenExpired(res.token);
  localStorage.getItem('hiredroid_key_SA') &&
    getConfigsCount().then((configs) => {
      localStorage.setItem(
        'hiredroid_key_22',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0]?.skill_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      localStorage.setItem(
        'hiredroid_key_23',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0]?.position_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      localStorage.setItem(
        'hiredroid_key_24',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0]?.education_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      localStorage.setItem(
        'hiredroid_key_25',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0]?.certification_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      localStorage.setItem(
        'hiredroid_key_26',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0].project_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
      localStorage.setItem(
        'hiredroid_key_27',
        CryptoJS.AES.encrypt(
          JSON.stringify(configs[0].interviewer_config_count),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
      );
    });
  return res;
};
export const loginRecheck = async (data) => {
  const response = await fetch(`${port}login`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  return res;
};
export const getSuperAdminUser = async () => {
  const jwtToken = getJwtToken();
  const response = await fetch(`${port}getSuperAdminUser`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const getSuperAdminUserReverse = async () => {
  const jwtToken = getJwtToken();
  const response = await fetch(`${port}getSuperAdminUserReverse`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const contactUsSend = async (data) => {
  const response = await fetch(`${port}contactUs`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  return res;
};

export const UpdateLoginForm = async (data) => {
  const response = await fetch(`${port}ManageAccount/UpdateLogin`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  res['status'] = response.status;
  localStorage.setItem('hiredroid_key_41', JSON.stringify(res['agencyId']));
  localStorage.setItem('agencyName', JSON.stringify(res['agencyName']));
  switch (response.status) {
    case 200:
      setToken(res.token);
      break;
    case 401:
      console.error('401 UnAutthorized');
      removeToken();
      break;
    default:
      removeToken();
      console.error(response);
  }
  isTokenExpired(res.token);
  return response.status;
};

export const getApplicantAppliedJobs = async (id, query_params = '') => {
  const jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getApplicantByAppliedJob/${id}/company/${getCompanyId()}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const getInterviewerJobs = async (email, page, pageSize, search) => {
  const jwtToken = getJwtToken();
  const val = {
    interviewer_email: email,
  };
  const response = await fetch(
    `${port}getInterviewerHistory/${getCompanyId()}?page_size=${pageSize}&page=${page}&search=${search}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(val),
    }
  );

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const getInterviewsHistory = async (userId, page, pageSize, search) => {
  const jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInterviewsHistory/${getCompanyId()}/User/${userId}?page_size=${pageSize}&page=${page}&search=${search}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return data;
};
export const addCustomer = async (values) => {
  var val = {
    FirstName: values['firstname'],
    MiddleName: values['middlename'],
    LastName: values['lastname'],
    Email: values['emailaddress'],
    Phonenumber: values['phone'],
    CustomerId: values['customerId'],
    UserId: values['employeeId'],
    Agencyid: getAgencyid(),
  };
  const response = await fetch(`${port}ManageCustomer/addCustomer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const deleteCustomer = async (id) => {
  const response = await fetch(
    `${port}ManageCustomer/DeleteCustomer?id=${id}`,
    {
      method: 'delete',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const fetchCustomerTable = async (values) => {
  var val = {
    UserId: getUserId(),
    RoleId: getRoleId(),
    Agencyid: getAgencyid(),
  };

  const response = await fetch(`${port}ManageCustomer/GetCustomers`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const customerEmailCheck = async (email) => {
  const response = await fetch(
    `${port}ManageCustomer/CheckEmail?email=${email}`
  );

  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response;
};

export const customerPhoneCheck = async (phone) => {
  const response = await fetch(
    `${port}ManageCustomer/CheckPhone?phone=${phone}`
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response;
};

export const addEmployee = async (values) => {
  var val = {
    FirstName: values['firstname'],
    MiddleName: values['middlename'],
    LastName: values['lastname'],
    Email: values['emailaddress'],
    Phonenumber: values['phone'],
    EmployeeId: values['employeeId'],
    AgencyId: getAgencyid(),
  };

  const response = await fetch(`${port}ManageEmployee/addEmployee`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const deleteEmployee = async (id) => {
  const response = await fetch(
    `${port}ManageEmployee/DeleteEmployee?id=${id}`,
    {
      method: 'delete',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return null;
};

export const fetchEmployeeTable = async () => {
  const response = await fetch(
    `${port}ManageEmployee/GetEmployee?AgencyId=${getAgencyid()}`,
    { method: 'post' }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const employeeEmailCheck = async (email) => {
  const response = await fetch(
    `${port}ManageEmployee/CheckEmail?email=${email}`
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response;
};

export const employeePhoneCheck = async (phone) => {
  const response = await fetch(
    `${port}ManageEmployee/CheckPhone?phone=${phone}`
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response;
};

export const fetchPropertyPurpose = async () => {
  const response = await fetch(`${port}ManageProperty/GetPropertyPurpose`);
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const fetchPropertyType = async () => {
  const response = await fetch(`${port}ManageProperty/GetPropertyType`);
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const searchProperty = async (values) => {
  var val = {
    // "PropertyId":values['firstname'],
    PropertyPurposeId: values['purpose'] ? values['purpose'] : 0,
    PropertyTypeId: values['propertytype'] ? values['propertytype'] : 0,
    MaxPrice: values['maxprice'] ? values['maxprice'] : 0,
    MinPrice: values['minprice'] ? values['minprice'] : 0,
    Keyword: values['keyword'] ? values['keyword'] : '',
    // "LocationId":values['location']?values['location']:0,
    Location: values['location'] ? values['location'] : '',
    SubLocation: values['phase'] ? values['phase'] : 0,
    CityId: values['city'] ? values['city'] : 0,
    MinArea: values['minarea'] ? values['minarea'] : 0,
    MaxArea: values['maxarea'] ? values['maxarea'] : 0,
    CountryId: values['country'] ? values['country'] : 0,
    agencyId: getAgencyid(),
    UserId: values['properties'] ? getUserId() : 0,
  };

  const response = await fetch(`${port}ManageProperty/SearchProperty`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(data.errors);
  }
  return data;
};

export const postJob = async (data) => {
  //console.log('data from service', data);
  let jwtToken = getJwtToken();

  const response = await fetch(`${port}createJobPost`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  //console.log('data of response', res);
  if (response.status >= 400) {
    console.error(response.status);
  }
  return res;
};
export const getConfigsCount = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getSystemConfig`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const updateSystemConfig = async (sysData) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateSystemConfig`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(sysData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const getCountries = async (countriesPage, searchValue, pageSize) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllCountries${
      countriesPage
        ? `?page=${countriesPage}&page_size=${
            pageSize ? pageSize : '25'
          }&search=${searchValue}`
        : ''
    }`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const getCountriesWithoutPagination = async (page, searchValue) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllCountries?page_size=&page=&search=`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const GetUserLocation = async () => {
  localStorage.setItem(
    'hiredroid_key_9',
    CryptoJS.AES.encrypt(
      JSON.stringify('null'),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString()
  );
  localStorage.setItem('hiredroid_key_45', null);
  localStorage.setItem(
    'hiredroid_key_15',
    CryptoJS.AES.encrypt(
      JSON.stringify('null'),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString()
  );

  const response = await fetch(
    `https://ipfind.co/me?auth=dbe817fa-2c28-4e15-be56-40ad7baadcff`
  );
  const data = await response.json();

  if (response.status >= 400) {
    // console.error(response.status);
  }
  if (response.status == 200) {
    localStorage.setItem(
      'hiredroid_key_9',
      CryptoJS.AES.encrypt(
        JSON.stringify(data.country_code),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    localStorage.setItem('hiredroid_key_45', data.region);
    localStorage.setItem(
      'hiredroid_key_15',
      CryptoJS.AES.encrypt(
        JSON.stringify(data.city),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    localStorage.setItem('hiredroid_key_44', data.country_code);
    return data;
  } else {
    localStorage.setItem(
      'hiredroid_key_9',
      CryptoJS.AES.encrypt(
        JSON.stringify('null'),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    localStorage.setItem('hiredroid_key_45', null);
    localStorage.setItem(
      'hiredroid_key_15',
      CryptoJS.AES.encrypt(
        JSON.stringify('null'),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
    return null;
  }
};

// export const getInterviewStatusList = async () => {
// let jwtToken = getJwtToken();
// const response = await fetch(`${port}getInterviewStatusList`, {
//   method: 'get',
//   headers: {
//     Authorization: `Bearer ${jwtToken}`,
//     'Content-Type': 'application/json',
//   },
// });
// const data = await response.json();
// if (response.status >= 400) {
//   console.error(response.status);
// }
//   return [
//     { inter_status_auto_id: 1, status_name: 'Applied' },
//     { inter_status_auto_id: 7, status_name: 'Shortlisted (Recruiter)' },
//     { inter_status_auto_id: 2, status_name: 'Shortlisted (Manager)' },
//     { inter_status_auto_id: 3, status_name: 'Interview' },
//     { inter_status_auto_id: 4, status_name: 'Offered' },
//     { inter_status_auto_id: 5, status_name: 'Hired' },
//     { inter_status_auto_id: 6, status_name: 'Rejected' },
//     { inter_status_auto_id: 8, status_name: 'Reviewed' },
//     { inter_status_auto_id: 9, status_name: 'Screening' },
//     { inter_status_auto_id: 10, status_name: 'Assessment' },
//     { inter_status_auto_id: 11, status_name: 'Background Checks' },
//     { inter_status_auto_id: 12, status_name: 'Onboarding' },
//   ];
// };

export const getInterviewStatusList = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getInterviewStatusList`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getHiringList = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInterviewerRecruiterList/${getCompanyId()}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getInterviewerNotRecruiterList = async (page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getInterviewerNotRecruiterList/${getCompanyId()}?page_size=25&page=${page}&search=${search}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getRecruiterList = async (page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getRecruiterList/${getCompanyId()}?page_size=25&page=${page}&search=${search}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
export const getToolTips = async () => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getTooltipLabels`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const getJobStatuses = async (jobId, companyId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getJobStatuses/${jobId}/company/${companyId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const fetchCities = async (
  countryId,
  citiesPage,
  search,
  citiesPageSize
) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getCitiesByCountry/${countryId}${
      citiesPage &&
      `?page=${citiesPage}&page_size=${
        citiesPageSize ? citiesPageSize : '25'
      }&search=${search}`
    }`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const fetchCitiesWithoutPagination = async (countryId, page, search) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getCitiesByCountry/${countryId}?page_size=&page=&search=`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const fetchStates = async (countryId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getStatesByCountry/${countryId}`, {
    method: 'get',
    headers: {
      // Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getCitiesPakistan = async (data) => {
  //console.log('data from service of cities', data);

  const response = await axios.post(
    `http://159.89.39.208/api/ManageCountry/GetCity?countryId=72`
  );
  const res = response.data;
  //console.log('data of response of cites', res);
  if (response.status >= 400) {
    console.error(response.status);
  }
  return res;
};

export const fetchAreaUnits = async () => {
  const response = await fetch(`${port}ManageProperty/GetAreaUnit`);
  const data = response;
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const fetchPropertyPurposeDetail = async () => {
  const response = await fetch(
    `${port}ManageProperty/GetPropertyPurposeDetail`
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const fetchCustomers = async (val) => {
  const response = await fetch(
    `${port}ManageCustomer/CheckCustomer?customer=${val}`,
    {
      method: 'post',
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const addProperty = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateUserProfileInfo`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(values),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const addWorkExperience = async (values) => {
  const response = await fetch(`${port}updateUserWorkHistory`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const addSkill = async (values) => {
  const response = await fetch(`${port}addUserSkill`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const deleteSkill = async (values) => {
  const response = await fetch(`${port}deleteUserSkill`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const createUsersPrivate = async (values) => {
  const bodyData = values;
  bodyData.user_company_id = getCompanyId();
  let jwtToken = getJwtToken();
  delete values.id;
  const response = await fetch(`${port}createUsersPrivate`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const updateUsersPrivate = async (values, id) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}updateUserPrivate/${id}`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  return data;
};
export const submitProfile = async (values, userId) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}users/${userId}/profile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (response.status >= 400) {
    // throw new Error(data.errors);
  }

  const data = await response.json();

  return data;
};

export const editAgency = async (values) => {
  var form_data = new FormData();
  form_data.append('AgencyId', values['id']);
  form_data.append('AgencyName', values['name']);
  for (var index = 0; index < values['image'].fileList.length; index++) {
    form_data.append('Image', values['image'].fileList[index].originFileObj);
  }
  // form_data.append('Image', values['image'].fileList[0].originFileObj);
  const response = await fetch(`${port}ManageProperty/UpdateAgencyAndLogo`, {
    method: 'post',
    body: form_data,
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const fetchProperties = async (PageNo, PageSize) => {
  var val = {
    PageNo: PageNo,
    PageSize: PageSize,
    // "RoleId": getRoleId(),
    // "UserId": getUserId()
    RoleId: 1,
    UserId: 1,
    AgencyId: getAgencyid(),
    // "FirstName": values['firstname'],
    // "MiddleName": values['middlename'],
    // "LastName": values['lastname'],
    // "Email": values['emailaddress'],
    // "Phonenumber": values['phone'],
    // "EmployeeId": values['employeeId'],
  };

  const response = await fetch(`${port}ManageProperty/GetPropertyDetail`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(data.errors);
  }
  return data;
};
export const fetchMyProperties = async (PageNo, PageSize) => {
  var val = {
    PageNo: PageNo,
    PageSize: PageSize,
    // "RoleId": getRoleId(),
    // "UserId": getUserId()
    RoleId: getRoleId(),
    UserId: getUserId(),
    AgencyId: getAgencyid(),
    // "FirstName": values['firstname'],
    // "MiddleName": values['middlename'],
    // "LastName": values['lastname'],
    // "Email": values['emailaddress'],
    // "Phonenumber": values['phone'],
    // "EmployeeId": values['employeeId'],
  };

  const response = await fetch(`${port}ManageProperty/GetPropertyDetail`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(data.errors);
  }
  return data;
};

export const addClassified = async (values) => {
  var form_data = new FormData();
  if (values['PropertyDetailsId']) {
    form_data.append('PropertyDetailsId', values['PropertyDetailsId']);
  }
  if (values['Propertystatus']) {
    form_data.append('ClassifiedsStatus', values['Propertystatus'] ? 1 : 0);
  }
  form_data.append('Description', values['Description']);
  form_data.append('PropertyTitle', values['title']);
  form_data.append('CustomerFistname', values['customerfirst']);
  form_data.append('CustomerLastname', values['customerlast']);
  form_data.append('CustomerEmail', values['username']);
  form_data.append('CustomerPhone', values['phonenumber']);
  form_data.append('Price', values['price']);
  form_data.append('LandArea', values['landArea']);
  form_data.append('AreaUnitId', values['unit']);
  form_data.append('PropertypurposeId', values['purpose']);
  form_data.append('PropertypurposedetailsId', values['purposeDetail']);
  form_data.append('PropertytypeId', values['type']);
  form_data.append('CityId', values['city']);
  form_data.append('LocationId', values['location']);
  form_data.append('PropertyBlockId', values['Block']);
  form_data.append('PropertyPhaseId', values['Phase']);
  form_data.append('Propertystreet', values['Street']);
  form_data.append('PropertyAddressNo', values['propertyNumber']);
  for (var index = 0; index < values['image'].fileList.length; index++) {
    form_data.append('Images', values['image'].fileList[index].originFileObj);
  }
  // form_data.append('Image', values['image'].fileList[0].originFileObj);
  const response = await fetch(`${port}ManageClassifieds/addClassified`, {
    method: 'post',
    body: form_data,
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const getClassifiedAds = async (values) => {
  const response = await fetch(`${port}ManageClassifieds/GetClassified`);
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(data.errors);
  }
  return data;
};

export const deleteClassified = async (id) => {
  const response = await fetch(
    `${port}ManageClassifieds/DeleteClassifieds?id=${id}`,
    {
      method: 'delete',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const assignProperty = async (values) => {
  var val = {
    UserId: values['employee'],
    PropertyDetailsId: values['propertyId'],
  };

  const response = await fetch(`${port}ManageProperty/addAssignProperty`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const deleteProperty = async (id) => {
  const response = await fetch(
    `${port}ManageProperty/DeletePropertyDetail?id=${id}`,
    {
      method: 'delete',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};
export const publishProperty = async (id) => {
  const response = await fetch(
    `${port}ManageProperty/PublishProperty?id=${id}&agencyId=${getAgencyid()}&userId=${getUserId()}`,
    {
      method: 'post',
    }
  );
  // const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
  }
  return response.status;
};

export const assignPermission = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}setPermissionsForRole`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return response.status;
};
export const fetchRoles = async (values) => {
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getUserRoles`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(data.errors);
  }
  return data;
};
export const fetchUsers = async (values) => {
  // const response = await fetch(`${port}ManageAccount/GetUsers`)
  // const data = await response.json();
  // if (response.status >= 400) {
  //     console.error(data.errors)
  // }
  // return data;
  var val = {
    UserId: getUserId(),
    RoleId: getRoleId(),
    Agencyid: getAgencyid(),
  };

  const response = await fetch(`${port}ManageAccount/GetUsers`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const submitRolesToUser = async (values) => {
  // var form_data = new FormData();
  // form_data.append('hiredroid_key_1', values['user']);
  // // for (var index = 0; index < values['image'].fileList.length; index++) {
  // //     form_data.append('Images', values['image'].fileList[index].originFileObj);
  // //   }
  // values.roles.map((role) => {
  //   form_data.append('Roles', role);
  // });
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}setRoleForUser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return response.status;
};

export const fetchAssignedPermissions = async (values) => {
  const response = await fetch(
    `${port}ManageRolesAndPermission/GetAssignPermissionsToRole`
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(data.errors);
  }
  return data;
};
export const fetchAssignedRoles = async (values) => {
  const response = await fetch(
    `${port}ManageRolesAndPermission/GetAssignRolesToUser`
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(data.errors);
  }
  return data;
};

export const getlocations = async (cityid) => {
  const response = await fetch(
    `${port}ManageCountry/GetLocation?cityId=${cityid}`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getlocationsbyname = async (city) => {
  const response = await fetch(
    `${port}ManageCountry/GetLocationByName?cityName=${city}`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const getsublocationsbyname = async (location) => {
  const response = await fetch(
    `${port}ManageCountry/GetSubLocationByName?subLocationName=${location}`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const SendEmailToCustomer = async (values) => {
  var val = {
    Name: values['Name'],
    AgentEmail: values['Email'],
    Message: values['Message'],
    CustomerEmail: values['CustomerEmail'],
  };
  const response = await fetch(`${port}ManageProperty/ContactCustomer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const getRolesForUser = async (value) => {
  var val = {
    user_id: value,
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getRoleByUser`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  let roles = data?.map((item) => {
    return item.role_auto_id;
  });
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return roles;
};

export const getPermissionsForRole = async (value) => {
  var val = {
    role_id: value,
  };
  let jwtToken = getJwtToken();
  const response = await fetch(`${port}getPermissionByRole`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  let permission = data?.map((item) => {
    return item.permission_auto_id;
  });
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return permission;
};

export const ResendSignupUser = async (userdata) => {
  const response = await fetch(`${port}resendSignupUser`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  const data = await response.json();

  if (response.status >= 400) {
    console.error(response.status);
  }
  data.status = response.status;
  return data;
};

export const getAllInterviewsOntheJob = async (jobId, query_params) => {
  let jwtToken = getJwtToken();
  const response = await fetch(
    `${port}getAllInterviewsOntheJob/${jobId}?${query_params}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    // console.error(response.status);
  }
  return data;
};
