import { DISPLAY_ASSIGN_PERMISSION_TABLE,DISPLAY_ASSIGN_ROLE_TABLE ,SUBMIT_ROLE_FORM, DISPLAY_PERMISSION_TABLE, DISPLAY_ROLE_TABLE, DELETE_ROLE, GET_ROLE_TABLE} from '../constants/Roles';

const initialstate = {
  isLoaded : false
}
const roleReducer = (state = initialstate, action) => {
    switch (action.type) {
        case SUBMIT_ROLE_FORM:
            return true;
        case DISPLAY_ROLE_TABLE:
              return {...state,roles: action.roletable, isLoaded: true};
              case DISPLAY_PERMISSION_TABLE:
              return {...state,permissions: action.permissiontable};
              case DISPLAY_ASSIGN_PERMISSION_TABLE:
              return {...state,assignpermissions: action.assignpermissiontable};
              case DISPLAY_ASSIGN_ROLE_TABLE:
              return {...state,assignroles: action.assignroletable};


        case DELETE_ROLE:
              return true;
          default:
              return state;
    }
};

export default roleReducer;
