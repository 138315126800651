import { message } from 'antd';
import { put, call, takeEvery } from 'redux-saga/effects';
import { SUBMIT_PROPERTY_FORM } from '../constants/Property';
import { submitpropertyfinish } from '../actions/Property';
import { addProperty } from '../services';
// import { loggingsuccess, loggingnewuser,loginloadingstart,loginloadingstop} from '../actions/Login'
// import { erroremail,inverterroremail } from '../actions/Register';

export function* handlePropertyForm(data) {
  try {
    // yield put(loginloadingstart())
    const status = yield call(addProperty, data.payload);
    if (status === 200) {
      if (data.payload.PropertyDetailsId) {
        message.success(`${process.env.REACT_APP_MSG_INFORMATION_UPDATED}`, [
          1,
        ]);
      } else {
        message.success(`${process.env.REACT_APP_MSG_INFORMATION_ADDED}`, [1]);
        data.resetform();
        yield put(submitpropertyfinish());
      }
    } else {
      message.error(`${process.env.REACT_APP_MSG_SOMETHING_WRONG}`, [1]);
      yield put(submitpropertyfinish());
    }
  } catch (error) {}
}

export default function* watchPropertyForm() {
  yield takeEvery(SUBMIT_PROPERTY_FORM, handlePropertyForm);
}
