import {
  REGISTER_NEW_USER,
  LOGGING_OUT,
  SUCCESS_REGISTER_EMAIL,
  UPDATE_NEW_USER,
  SUCCESS_UPDATE_EMAIL,
  PASSWORD_NEW_USER,
  SUCCESS_PASSWORD_EMAIL,
  ERROR_EMAIL,
  INVERT_ERROR_EMAIL,
  SPINNER_BUTTON_EMAIL,
  DECRYPT_EMAIL,
  INITIAL_DECRYPT_EMAIL,
  REOPEN_REGISTER_EMAIL,
  REOPEN_LOGIN_EMAIL,
  REOPEN_VERIFICATION_EMAIL,
  REOPEN_ERROR_EMAIL,
  FORGET_PASSWORD,
  BLOCK_EMAIL,
  SUCCESS_REGISTER_USER,
  VERIFICATION_EMAIL,
  ERROR_EMAIL_DISABLED,
} from '../constants/Register';

export const registeruser = (userdata, func, func1) => ({
  type: REGISTER_NEW_USER,
  payload: userdata,
  function: func,
  newFunction: func1,
});
export const forgetpassword = (userdata) => ({
  type: FORGET_PASSWORD,
  payload: userdata,
});
export const updateuser = (userdata) => ({
  type: UPDATE_NEW_USER,
  payload: userdata,
});
export const passworduser = (func, userdata) => ({
  type: PASSWORD_NEW_USER,
  payload: userdata,
  function: func,
});
export const successregisteremail = () => ({
  type: SUCCESS_REGISTER_EMAIL,
});
export const successregisteruser = () => ({
  type: SUCCESS_REGISTER_USER,
});
export const blockemail = () => ({
  type: BLOCK_EMAIL,
});
export const reopenregisteremail = () => ({
  type: REOPEN_REGISTER_EMAIL,
});
export const reopenloginemail = () => ({
  type: REOPEN_LOGIN_EMAIL,
});
export const reopenverification = () => ({
  type: REOPEN_VERIFICATION_EMAIL,
});
export const reopenerroremail = () => ({
  type: REOPEN_ERROR_EMAIL,
});
export const loggingoutalert = () => ({
  type: LOGGING_OUT,
});
export const successpasswordemail = () => ({
  type: SUCCESS_PASSWORD_EMAIL,
});
export const successupdateemail = () => ({
  type: SUCCESS_UPDATE_EMAIL,
});
export const erroremail = () => ({
  type: ERROR_EMAIL,
});
export const resendverification = () => ({
  type: VERIFICATION_EMAIL,
});
export const inverterroremail = () => ({
  type: INVERT_ERROR_EMAIL,
});
export const spinnerbuttonemail = () => ({
  type: SPINNER_BUTTON_EMAIL,
});
export const decryptemail = (email) => ({
  type: DECRYPT_EMAIL,
  payload: email,
});
export const initialdecryptemail = (email) => ({
  type: INITIAL_DECRYPT_EMAIL,
  payload: email,
});
export const erroremaildisable = () => ({
  type: ERROR_EMAIL_DISABLED,
});
