import { put, call, takeEvery, select } from 'redux-saga/effects';

// import { setImages, setError } from '../actions';
import { DELETE_ROLE } from '../constants/Roles';
import { deleteRole, fetchRoleTable } from '../services';
import { getroletable } from '../actions/Roles';

export function* handleDelete(data) {
  try {
    yield call(deleteRole, data.payload);

    yield call(handleRoleTable);

    // yield put(setImages(images));
  } catch (error) {
    // yield put(setError(error.toString()));
  }
}
export function* handleRoleTable() {
  try {
    const data = yield call(fetchRoleTable);

    yield put(getroletable(data));
    // yield put(setImages(images));
  } catch (error) {
    // yield put(setError(error.toString()));
  }
}

export default function* watchDelete() {
  yield takeEvery(DELETE_ROLE, handleDelete);
}
